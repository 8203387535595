
<template lang="pug">
    vs-sidebar.add-new-data-sidebar.items-no-padding(click-not-close="" position-right="" parent="body" default-index="1" color="primary" spacer="" v-model="isSidebarActiveLocal")
      // หัวเรื่อง
      .mt-6.flex.items-center.justify-between.px-6
        h4
          | ค้นหาสินค้าในสต็อค
          //- | {{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM
        feather-icon.cursor-pointer(icon="XIcon" @click.stop="isSidebarActiveLocal = false")
      // เส้นแบ่ง
      vs-divider.mb-0

      // VuePerfectScroll
      vue-perfect-scrollbar.scroll-area--data-list-add-new(:settings="settings" :key="$vs.rtl")

        // FORM ใส่ข้อมูล
        .p-6.pt-10
          .mb-base
            .grid-layout-container.alignment-block.pb-8(style="width:100%;")
              //*** คำค้นหา
              vs-row(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12') คำค้นหา
              vs-row(vs-w='12')
                vs-col.pb-8(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  vs-input.w-full(placeholder='คำค้นหาชื่อชุด รหัสสินค้า', v-model='filterData.searchText', style='border-radius:20px;', placehoder='ชื่อหรือรหัสสินค้า')

              //*** ประเภท
              vs-row(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12') ประเภท
              vs-row.pb-8(vs-w='12')
                vs-col(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  multiselect(
                    v-model="categorySelected",
                    :options="categories",
                    label="description"
                    track-by="code",
                  )
                  span(slot="noResult").
                    ไม่พบข้อมูล

              //*** ชนิด
              vs-row(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12') ชนิด
              vs-row.pb-8(vs-w='12')
                vs-col(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  multiselect(
                    v-model="textureSelected",
                    :options="textures",
                    label="description"
                    track-by="code",
                  )
                  span(slot="noResult").
                    ไม่พบข้อมูล

              //*** สี
              vs-row(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12') สี
              vs-row.pb-8(vs-w='12')
                vs-col(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  multiselect(
                    v-model="color_value",
                    :options="colors",
                    :multiple="true",
                    :group-select="true",
                    label="description"
                    track-by="code",
                    placeholder="พิมพ์เพื่อค้นหา",

                  )
                    span(slot="noResult").
                      ไม่พบข้อมูล

              //*** ไซส์
              vs-row(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12') ไซส์
              vs-row.pb-8(vs-w='12')
                vs-col(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  multiselect(
                    v-model="size_value",
                    :options="sizes",
                    :multiple="true",
                    :group-select="true",
                    label="description"
                    track-by="code",
                    placeholder="พิมพ์เพื่อค้นหา",

                  )
                    span(slot="noResult").
                      ไม่พบข้อมูล



              //*** ACTIVE
              vs-row(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12') กำลัง Active
              vs-row.pt-3.pb-8(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  div(:class="[active_value === 'ไม่ระบุ' || active_value === null ? 'choose' : 'notChoose']" @click="active_value='ไม่ระบุ'") ไม่ระบุ
                  div(:class="[active_value === 'ใช่' ? 'choose' : 'notChoose']" @click="active_value='ใช่'") ใช่
                  div(:class="[active_value === 'ไม่ใช่' ? 'choose' : 'notChoose']" @click="active_value='ไม่ใช่'") ไม่ใช่



              //*** onWeb
              vs-row(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12') แสดงบนหน้าเว็บ
              vs-row.pt-3.pb-8(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  div(:class="[onWeb_value === 'ไม่ระบุ' || onWeb_value === null ? 'choose' : 'notChoose']" @click="onWeb_value='ไม่ระบุ'") ไม่ระบุ
                  div(:class="[onWeb_value === 'ใช่' ? 'choose' : 'notChoose']" @click="onWeb_value='ใช่'") ใช่
                  div(:class="[onWeb_value === 'ไม่ใช่' ? 'choose' : 'notChoose']" @click="onWeb_value='ไม่ใช่'") ไม่ใช่

                  //- multiselect(
                  //-   v-model="onWeb_value",
                  //-   :options="truefalseOption",
                  //-   :multiple="false",

                  //- )
                  //-   span(slot="noResult").
                  //-     ไม่พบข้อมูล

              //*** Hot Item
              vs-row(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12') Hot Item
              vs-row.pt-3.pb-8(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  div(:class="[hotItem_value === 'ไม่ระบุ' || hotItem_value === null ? 'choose' : 'notChoose']" @click="hotItem_value='ไม่ระบุ'") ไม่ระบุ
                  div(:class="[hotItem_value === 'ใช่' ? 'choose' : 'notChoose']" @click="hotItem_value='ใช่'") ใช่
                  div(:class="[hotItem_value === 'ไม่ใช่' ? 'choose' : 'notChoose']" @click="hotItem_value='ไม่ใช่'") ไม่ใช่


              //*** New Arrival
              vs-row(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12') New Arrival
              vs-row.pt-3.pb-8(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  div(:class="[newArrival_value === 'ไม่ระบุ' || newArrival_value === null ? 'choose' : 'notChoose']" @click="newArrival_value='ไม่ระบุ'") ไม่ระบุ
                  div(:class="[newArrival_value === 'ใช่' ? 'choose' : 'notChoose']" @click="newArrival_value='ใช่'") ใช่
                  div(:class="[newArrival_value === 'ไม่ใช่' ? 'choose' : 'notChoose']" @click="newArrival_value='ไม่ใช่'") ไม่ใช่



            //- .flex.items-center.mb-8


            //- h6.mb-4 ข้อมูลแสดงสินค้าบนหน้าเว็บไซต์
            //- .flex.items-center.mb-8
            //-   vs-switch(v-model="filterData.active")
            //-   span.ml-4 Active
            //- .flex.items-center.mb-8
            //-   vs-switch(v-model="filterData.onWeb")
            //-   span.ml-4 Show on Website
            //- .flex.items-center.mb-8
            //-   vs-switch(v-model="filterData.hotItem")
            //-   span.ml-4 Hot Item
            //- .flex.items-center.mb-8
            //-   vs-switch(v-model="filterData.newArrival")
            //-   span.ml-4 New Arrival

      // **************** SUBMIT ****************
      .flex.flex-wrap.items-center.p-6(slot="footer")
        //- vs-button.mr-6(@click="submitData" :disabled="!isFormValid") Submit
        vs-button.mr-6(@click="submitData" ) ค้นหา
        vs-button(type="border" color="danger" @click="clearSeachParams()" ) เคลียร์ข้อมูลค้นหา

</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import shapeFormat from "@/mixins/shapeFormat.js";
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";

export default {
  mixins: [shapeFormat],
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },
    itemIndex: {
      type: Number
    }
  },

  data() {
    return {
      filterData:{
        searchText: "",
        active: null
          ,
        onWeb : null,
        newArrival: null,
        hotItem: null
      },
      active_value : "ไม่ระบุ",
      onWeb_value : "ไม่ระบุ",
      hotItem_value : "ไม่ระบุ",
      newArrival_value : "ไม่ระบุ",
      truefalseOption: ["ไม่ระบุ","ใช่","ไม่ใช่"],
      categories: [],
      colors: [],
      textures: [],
      sizes: [],
      categorySelected: {},
      textureSelected: {},
      color_value: null,
      size_value: null,
      loadedBranch: [],
      itemAmount: 0,
      minimumRemaining: 0,
      remainingItems: 0,
      assignItemPanelActive: false,
      pg : {},
      webItem:false,
      hotItem: false,
      newArrival: false,
      recommended: false,
      webcat_value: [],
      allWebcat: [],
      category_choices: [
        { text: "Audio", value: "audio" },
        { text: "Computers", value: "computers" },
        { text: "Fitness", value: "fitness" },
        { text: "Appliance", value: "appliance" }
      ],

      order_status_choices: [
        { text: "Pending", value: "pending" },
        { text: "Canceled", value: "canceled" },
        { text: "Delivered", value: "delivered" },
        { text: "On Hold", value: "on_hold" }
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },

    };
  },
  async created(){




    await this.$store.dispatch(
      "productGroup/getAllInfo"
    );

    this.categories = this.$store.getters["productGroup/categories"];
    this.textures = this.$store.getters["productGroup/textures"];
    this.colors = this.$store.getters["productGroup/colors"];
    this.sizes = this.$store.getters["productGroup/sizes"];

    this.categorySelected = this.selectArray(this.categories, this.searchParam.category);
    this.textureSelected = this.selectArray(this.textures, this.searchParam.texture);
    this.color_value = this.selectArrayMany(this.colors, this.searchParam.color);
    this.size_value = this.selectArrayMany(this.sizes, this.searchParam.size);

    this.active_value = this.searchParam.active === null || this.searchParam.active === undefined ? "ไม่ระบุ" : this.searchParam.active;
    this.onWeb_value =  this.searchParam.onWeb === null || this.searchParam.onWeb === undefined ? "ไม่ระบุ" : this.searchParam.onWeb;
    this.hotItem_value = this.searchParam.hotItem === null || this.searchParam.hotItem === undefined ? "ไม่ระบุ" : this.searchParam.hotItem;
    this.newArrival_value = this.searchParam.newArrival === null || this.searchParam.newArrival === undefined ? "ไม่ระบุ" : this.searchParam.newArrival;


    if(Object.entries(this.searchParam).length > 0){
        this.filterData = {
                    searchText: this.searchParam.searchText,
                    active: this.searchParam.active,
                    onWeb : this.searchParam.onWeb,
                    newArrival: this.searchParam.newArrival,
                    hotItem: this.searchParam.hotItem
                  };
      }

    console.log('CREATED : this.searchParam >>> ',this.searchParam);


  },

  computed: {

     searchParam(){
        return this.$store.getters["productList/searchParameters"];
      },
    webcatObserver : {
      get(){

        return this.data.webCategory;
      },
      set(val){
        if(val === null || val === undefined || val === "" ){
          this.webcat_value = []
        }else{
          this.webcat_value = val.split("|");
        }
      }
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {

        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
        else{
            if(this.data.webCategory === null || this.data.webCategory === undefined || this.data.webCategory === "" ){
              this.webcat_value = []
            }else{
              this.webcat_value = this.data.webCategory.split("|");
            }


            if(this.data.webItem === "y")  this.webItem = true
            else this.webItem = false

            if(this.data.webHotItem === 'ใช่')  this.hotItem = true
            else this.hotItem = false

             if(this.data.webNewArrival === 'y')  this.newArrival = true
            else this.newArrival = false
        }


      }
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataName &&
        this.dataCategory &&
        this.dataPrice > 0
      );
    }
  },
  methods: {
    clearSeachParams(){
      this.$store.dispatch('productList/clearParm');
      this.isSidebarActiveLocal = false
    },

    selectArray(arr , code){
      var res = arr.filter(x => x.code === code);

      if(res.length <= 0 || res === null || res === undefined )
      {
          return null;
      }

      return res[0];

    },
    selectArrayMany(arr , code){


      if( code === "" || code === null || code === undefined )
      {
        return null;
      }

      var sp = code.split("|");

      var resList = [];

      for (let index = 0; index < sp.length; index++) {

        var res = arr.filter(x => x.code ===  sp[index] );

        if(res.length > 0){
          resList.push(res[0]);
        }

      }

      return resList;
    },

    async submitData() {

        var category = null;
        if(this.categorySelected !== null && this.categorySelected !== undefined)
        {
          if(Object.entries(this.categorySelected).length > 0){
            category = this.categorySelected.code
          }
        }

        var texture = null;
        if(this.textureSelected !== null && this.textureSelected !== undefined)
        {
            if(Object.entries(this.textureSelected).length > 0){
              texture = this.textureSelected.code
            }
        }

        var color = null
        if(this.color_value !== null && this.color_value !== undefined)
        {
            if(this.color_value.length > 0){

              for (let ci = 0; ci < this.color_value.length; ci++) {


                if(ci <= 0)
                {
                  color = this.color_value[ci].code;
                }
                else{
                  color =  color + "|" + this.color_value[ci].code;
                }

              }

            }
        }

        var size = null
        if(this.size_value !== null && this.size_value !== undefined)
        {
            if(this.size_value.length > 0){
              for (let si = 0; si < this.size_value.length; si++) {


                if(si <= 0)
                {
                  size = this.size_value[si].code;
                }
                else{
                  size = size + "|" +  this.size_value[si].code;
                }

              }
            }
        }





        var searchParams = {
          "searchText" : this.filterData.searchText,
          "category" : category,
          "texture" : texture,
          "color" : color,
          "size" : size,
          "onWeb" : this.onWeb_value,
          "active" : this.active_value,
          "hotItem" : this.hotItem_value,
          "newArrival" : this.newArrival_value,
        };



        this.$store.dispatch('productList/searchParm', searchParams);
        this.$emit("closeSidebar");
        this.$emit("returnSearchParameter")



    },




  },
  components: {
    VuePerfectScrollbar,
    Multiselect,
    "v-select": vSelect,


  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
