
<template lang="pug">
    vs-sidebar.add-new-data-sidebar.items-no-padding(click-not-close="" position-right="" parent="body" default-index="1" color="primary" spacer="" v-model="isSidebarActiveLocal")
      // หัวเรื่อง
      .mt-6.flex.items-center.justify-between.px-6
        h4
          | จำนวนสินค้าในสต็อค
          //- | {{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM
        feather-icon.cursor-pointer(icon="XIcon" @click.stop="isSidebarActiveLocal = false")
      // เส้นแบ่ง
      vs-divider.mb-0

      // VuePerfectScroll
      vue-perfect-scrollbar.scroll-area--data-list-add-new(:settings="settings" :key="$vs.rtl")

        // FORM ใส่ข้อมูล
        .p-6.pt-2
          //- .mb-base
          .flex.items-center.mb-8
            .grid-layout-container.alignment-block(style="vertical-align:text-top ;width:100%;padding-bottom:40px;")

              // **************** ข้อมูลสินค้ารูป ชื่อ โค้ด ****************
              vs-row.row-form(vs-w='12' style='margin-top:30px;')
                vs-col(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-lg="6", vs-sm="6", vs-xs="6", style="padding-right:30px;")
                  img.pb-6.responsive(:src="getImagepath(data.mainImage)" alt="img" style="width: 70%")
                vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="6", vs-sm="6", vs-xs="6")
                  vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
                    .grid-layout-container.alignment-block
                      vs-row(vs-w='12' style="font-size: 1.5rem;" ) {{data.name}}
                      vs-row(vs-w='12' style="font-size: 0.7rem;" ) ({{data.code}})

                //- vs-col(v-if="$acl.check('admin')" vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='3' vs-sm='3' vs-xs='3' style='cursor:pointer;')

              vs-row(vs-w='12')
                vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='12' vs-sm='12' vs-xs='12')
                  h4 สินค้ามีทั้งหมด {{ data.inStock }} ชิ้น
                vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="12", vs-sm="12", vs-xs="12")
                  h5(style="color:#7ED321;") ไม่ระบุสาขา {{ remainingItems }} ชิ้น

              // **************** จำนวนทั้งหมด ****************
              vs-row.row-form(vs-w='12' style='margin-top:30px;')
                vs-col(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-lg="6", vs-sm="6", vs-xs="6", style="padding-right:30px;")  จำนวนทั้งหมด
                //- vs-col.form-label.px-2(v-if="$acl.check('onlyStaff')" vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='3' vs-sm='3' vs-xs='3') {{ mybranchstock(productId) }}
                vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="6", vs-sm="6", vs-xs="6")
                  vs-input-number(style=' width:50px; color: red;' :min='minimumRemaining' v-model='data.inStock')
                //- vs-col(v-if="$acl.check('admin')" vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='3' vs-sm='3' vs-xs='3' style='cursor:pointer;')

              // **************** แบ่งตามสาขา ****************
              vs-row(style="margin-top:30px;", vs-w="12", v-for="(item, branch_index) in loadedBranch", :key="branch_index")
                vs-col(vs-type="flex", vs-justify="flex-end", vs-align="center", vs-lg="6", vs-sm="6", vs-xs="6", style="padding-right:30px;") {{ item.branchName }}
                vs-col(vs-type="flex", vs-justify="flex-start", vs-align="center", vs-lg="6", vs-sm="6", vs-xs="6")
                  vs-input-number(style="width:50px; color: red;", min="0", :max="remainingItems + loadedBranch[branch_index].amount", v-model="loadedBranch[branch_index].amount")

      // **************** SUBMIT ****************
      .flex.flex-wrap.items-center.p-6(slot="footer")
        //- vs-button.mr-6(@click="submitData" :disabled="!isFormValid") Submit
        vs-button.mr-6(@click="submitData" ) บันทึก
        vs-button(type="border" color="danger" @click="isSidebarActiveLocal = false") ยกเลิก


</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import shapeFormat from "@/mixins/shapeFormat.js";
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
// import {
//   convertToArray
// } from '@/utils'


export default {
  mixins: [shapeFormat],
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    },
    itemIndex: {
      type: Number
    }
  },
  watch: {
    loadedBranch: {
      handler() {
        var assignedItemsSum = 0;
        // SET ZERO
        for (var k = 0; k < this.loadedBranch.length; k++) {
          if(this.loadedBranch[k].amount)
          {
             if(this.loadedBranch[k].amount < 0)
             {
                this.loadedBranch[k].amount = 0
             }
          }

        }

        for (var i = 0; i < this.loadedBranch.length; i++) {
          assignedItemsSum = assignedItemsSum + this.loadedBranch[i].amount;

        }
        if(assignedItemsSum > this.data.inStock){
          this.data.inStock = assignedItemsSum;
          this.$swal({
            icon: 'warning',
            title: 'โปรดตรวจสอบจำนวนสินค้าในสต็อค',
          });
        }
        this.remainingItems = this.data.inStock - assignedItemsSum;
        this.minimumRemaining = assignedItemsSum;

        // console.log('this.loadedBranch >>>>>>> ',this.loadedBranch);

      },
      deep: true
    },

    data: {
       handler() {

        var assignedItemsSum = 0;

        for (var i = 0; i < this.loadedBranch.length; i++) {
          assignedItemsSum = assignedItemsSum + this.loadedBranch[i].amount;
        }
        if(assignedItemsSum > this.data.inStock){
          this.data.inStock = assignedItemsSum;
          this.$swal({
            icon: 'warning',
            title: 'โปรดตรวจสอบจำนวนสินค้าในสต็อค',
          });
        }
        this.remainingItems = this.data.inStock - assignedItemsSum;
        this.minimumRemaining = assignedItemsSum;
      },
      deep: true


    },

  },
  data() {
    return {
      loadedBranch: [],
      itemAmount: 0,
      minimumRemaining: 0,
      remainingItems: 0,
      assignItemPanelActive: false,
      pg : {},
      webItem:false,
      hotItem: false,
      newArrival: false,
      recommended: false,
      webcat_value: [],
      allWebcat: [],
      category_choices: [
        { text: "Audio", value: "audio" },
        { text: "Computers", value: "computers" },
        { text: "Fitness", value: "fitness" },
        { text: "Appliance", value: "appliance" }
      ],

      order_status_choices: [
        { text: "Pending", value: "pending" },
        { text: "Canceled", value: "canceled" },
        { text: "Delivered", value: "delivered" },
        { text: "On Hold", value: "on_hold" }
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },

    };
  },
  async created(){
    // await this.getAllWebcat();
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    // LOAD BRANCH
    var responseBranch;
    try {
      responseBranch = await this.$http.get(
        this.$store.state.apiURL + "/api/branch",
        this.$store.state.auth.apiHeader
      );
      if (responseBranch.status === 200) {
        this.branches = await responseBranch.data.filter(x => x.state === true);
      }
    } catch (error) {
      this.shapeFormat.noticeError(error);
      responseBranch = null;
    }

    // LOAD Stock
    var responseStock;
    try {
      responseStock = await this.$http.get(
        this.$store.state.apiURL + "/api/stock/" + this.data.productItemId,
        this.$store.state.auth.apiHeader
      );

      // console.log("STOCK RESPONSEStock >>> ", responseStock.data);
    } catch (error) {
      //  console.log("responseStock error >>> ", error);
      responseStock = null;
      // appropriately handle the error
    }
    this.thestock = await responseStock.data;

    //  console.log("responseStock >>> ", responseStock);

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    //  console.log("responseBranch >>> ", responseBranch);
    var lb = await responseBranch.data;

    // ERROR HERE

    //  console.log("responseStock >>> ", responseStock);

    var ls = await responseStock.data;

    // console.log(" 11111 lb >>>> ", lb);
    // console.log(" 11111 ls >>>> ", ls);

    var loaded = [];
    for (var i = 0; i < lb.length; i++) {
      var st = await ls.find(
        x =>
          x.branchId === lb[i].branchId &&
          x.productItemId === this.data.productItemId
      );
      // console.log("st >>> ", st.inStock, " [", i, "] ");
      var li = {
        branchId: await lb[i].branchId,
        branchName: await lb[i].branchName,
        productItemId: this.data.productItemId,
        amount: 0
      };

      if (st) {
        li.amount = await st.inStock;
      }

      await loaded.push(li);
    }

    // console.log("st  >>>>> ", st);

    this.loadedBranch = await loaded;

    // console.log('NEW LOAD BRANCH >>>> ',   this.loadedBranch );


  },


  computed: {
    webcatObserver : {
      get(){

        return this.data.webCategory;
      },
      set(val){
        if(val === null || val === undefined || val === "" ){
          this.webcat_value = []
        }else{
          this.webcat_value = val.split("|");
        }
      }
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {

        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
        else{
            if(this.data.webCategory === null || this.data.webCategory === undefined || this.data.webCategory === "" ){
              this.webcat_value = []
            }else{
              this.webcat_value = this.data.webCategory.split("|");
            }


            if(this.data.webItem === "y")  this.webItem = true
            else this.webItem = false

            if(this.data.webHotItem === 'ใช่')  this.hotItem = true
            else this.hotItem = false

             if(this.data.webNewArrival === 'y')  this.newArrival = true
            else this.newArrival = false
        }


      }
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataName &&
        this.dataCategory &&
        this.dataPrice > 0
      );
    }
  },
  methods: {
    // async getAllWebcat() {
    //   var allcate = await this.getOne("/api/webcategory");
    //   this.allWebcat =  convertToArray(allcate, "description");

    // },
    initValues() {

    },
    async submitData() {

      var resp = await this.saveStock();
      console.log('submitdata resp >>> ',resp);

      if(resp !== null && resp !== undefined )
      {
        this.$emit("closeSidebar");
        this.$emit("returnInStock", this.data.inStock, this.itemIndex)


      }

    },

    async saveStock() {
      var res = null;
      var stockSave = [];
      for (var i = 0; i < this.loadedBranch.length; i++) {
        var stock_items = {
          branchId: this.loadedBranch[i].branchId,
          productItemId: this.loadedBranch[i].productItemId,
          inStock: this.loadedBranch[i].amount
        };
        stockSave.push(stock_items);
      }

      var item = {
        productItemId : this.data.productItemId,
        inStock : this.data.inStock,
        stockList : stockSave
      };

      // console.log("TRY TO SAVE stockSave >>> ", stockSave);
      // var response = null;
      try {
        res = await this.$http.put(
          this.$store.state.apiURL +
            "/api/stock/update-stock-productitem/" +
            this.data.productItemId,
          item,
          this.$store.state.auth.apiHeader
        );

        // res = await this.$http.put(
        //   this.$store.state.apiURL + "/api/productitem/" + this.data.productItemId,
        //   this.data,
        //   this.$store.state.auth.apiHeader
        // );

      } catch (error) {
         res = null;
        this.$swal({
          icon: 'error',
          title: 'เกิดข้อผิดพลาดบันทึกข้อมูลไม่สำเร็จ',
        });
      }

      return res;

    },

    async saveItemOnlyInfo() {
      this.btnActive = !this.btnActive;

      var res = null;

      try {
        res = await this.$http.put(
          this.$store.state.apiURL + "/api/productitem/" + this.data.productItemId,
          this.data,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
        this.$swal({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด บันทึกข้อมูลไม่สำเร็จ',
        });
      }


      return res;

    },

    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    Multiselect,
    "v-select": vSelect

  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
