
<template>
<div id="main-background" >
    <update-stock-sidebar :key="updateStockKey" :itemIndex="itemind" :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" @returnInStock="InStockValue" :data="sidebarData"></update-stock-sidebar>
    <filter-stock-sidebar :key="filterStockKey"  :isSidebarActive="filterDataSidebar" @closeSidebar="toggleFilterDataSidebar" @returnSearchParameter="SearchParameterValue" :data="filterSidebarData"></filter-stock-sidebar>
    <wash-panel :key="washingKey" ></wash-panel>
    <catalog-panel :key="catalogKey" ></catalog-panel>
  <vx-card>



    <!-- TUTORIAL POP UP -->
    <template>
      <!-- TUTORIAL  POP UP -->
      <vs-popup
        classContent="popup-example label-font "
        title="TUTORIAL"
        :active.sync="tutorialPopupActive"
      >
        <div style="width:80%; margin: 5px auto;">
          <div class="vx-col w-full mb-base">
              <!-- <b-embed type="iframe" aspect="16by9" src="https://www.youtube.com/watch?v=UFuTAbXLHuQ&amp;feature=youtu.be" allowfullscreen/> -->
              <iframe width="560" height="315" src="https://www.youtube.com/embed/UFuTAbXLHuQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
        </div>
      </vs-popup>
      <!-- POP UP -->
    </template>

    <!-- ZOOM POP UP -->
    <template>
      <!-- ZOOM IMAGE POP UP -->
      <vs-popup
        classContent="popup-example label-font "
        title="Stock Image"
        :active.sync="zoomPopupActive"
      >
        <div style="width:400px; margin: 5px auto;">
          <img class="responsive rounded" :src="zoomzoom" style="width:95%;" />
        </div>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- REMOVE POP UP -->
    <template>
      <!-- REMOVE POP UP -->
      <vs-popup
        classContent="popup-example label-font"
        title="ลบข้อมูล"
        :active.sync="removePopupActive"
      >
        <!--  รหัส  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
            style="padding-left: 20px 30px;"
          >ลบสินค้า {{this.removeCode}}</vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button @click="clearParams()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button
              @click="closeRemovePanel()"
              style="margin:10px;"
              color="danger"
              type="filled"
            >เคลียร์ข้อมูล</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- POP UP ASSIGN ITEM TO BRANCH -->
    <template>
      <vs-popup
        classContent="popup-example"
        title="แบ่งสินค้าให้ตามสาขา"
        :active.sync="assignItemPanelActive"
      >
        <!-- ROW OF ASSIGN ITEM TO BRANCH -->
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="12"
          >
            <h4>สินค้ามีทั้งหมด {{currentAmountInstock}} ชิ้น</h4>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="12"
          >
            <h5 style="color:#7ED321;">มีสินค้ายังไม่ระบุสาขา {{remainingItems}} ชิ้น</h5>
          </vs-col>
        </vs-row>
        <div
          class="grid-layout-container alignment-block"
          style="vertical-align:text-top ;width:100%;padding-bottom:40px;"
        >
          <vs-row style="margin-top:30px;" vs-w="12" v-for="(item, index) in branches" :key="index">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              style="padding-right:30px;"
            >
              <b>{{item.branchName}}</b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >
              จำนวน &nbsp;&nbsp;&nbsp;
              {{amountOfAssignItem(item.branchId) }}

            </vs-col>
          </vs-row>
        </div>

        <!--------------------------------------------------------------------->
        <!-- <div style="border-top: 1px solid #E3E3E3; margin-bottom:30px; "></div> -->
        <!--------------------------------------------------------------------->

        <!--  ปุ่ม -->
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          style="padding : 20px 20px; background-color: #F8F8F8; border: 0.5px rgb(248, 248, 248);"
        >
          <vs-button
            style="background-color:#4A4A4A !important; width:120px;"
            @click="assignItemPanelActive=false;"
            type="filled"
          >ปิด</vs-button>
          <!-- <vs-button
            style="background-color:#7ED321 !important; margin-left:15px;"
            type="filled"
          >ดำเนินการ</vs-button>-->
          <vs-button
            :href="getRedirectLink()"
            target="blank"
            style=" margin-left:15px;"
            color="warning"
            type="filled"
          >ไปแก้ไขจำนวน</vs-button>
        </vs-row>
        <!--------------------------------------------------------------------->
      </vs-popup>
    </template>
    
    
    <!-- POP UP CHECK QUEUE -->
    <template>
      <vs-popup
        classContent="popup-example"
        title="เช็คคิวสินค้า"
        :active.sync="checkqueuePanelActive"
      >
        <!-- ROW OF CHECKQUEUE -->
        <vs-row
          style="display: flex;width: 100%;padding: 20px 0px;margin-bottom: 30px;border-bottom: 1px solid #e4e4e4;"
          vs-w="12"
          v-for="(checkitem, qindex) in checkqueueList"
          :key="qindex"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="3"
          >
            <div style="vertical-align: text-top;">
              <img
                class="responsive rounded"
                :src="getFullpath(checkitem.mainImage)"
                alt="user-upload"
                style="width:100px;"
              />
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="9"
            vs-sm="9"
            vs-xs="9"
          >
            <div
              class="grid-layout-container alignment-block"
              style="vertical-align:text-top ;width:100%;"
            >
              <!--  ข้อมูลสินค้า -->
              <vs-row class="row-form" vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >ข้อมูลสินค้า</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="9"
                  vs-sm="9"
                  vs-xs="9"
                >
                  <div
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <vs-row vs-w="12">
                      <b>{{checkitem.name}}</b>
                    </vs-row>
                    <vs-row
                      vs-w="12"
                      style="font-size: 11px;"
                    >{{checkitem.code}} (สาขา{{thisBranch.branchName}})</vs-row>
                  </div>
                </vs-col>
              </vs-row>

              <!--  คิวเช่า -->

              <!-- จำนวนสินค้าในสต็อค-->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >จำนวนสินค้าในสาขา</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="5"
                  vs-sm="5"
                  vs-xs="5"
                  style="z-index : 20 !important;"
                >
                  <span
                    style="font-size:18px; font-family:mitr; color:#7ED321; "
                  > {{checkitem.branchStock[0].inStock }} ชุด</span>
                </vs-col>
              </vs-row>
              <!--  ช่วงเวลา -->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >ช่วงเวลา</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="7"
                  vs-sm="7"
                  vs-xs="7"
                  style="z-index : 20 !important;"
                >
                  <!-- เช็คคิวสินค้า -->
                <div
              class="grid-layout-container alignment-block"
              style="vertical-align:text-top ;width:100%;"
            >
                 <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                    <v-date-picker
                      style="width:80%;"
                      :locale="{ id: 'th', firstDayOfWeek: 2, masks: {  L: 'DD/MM/YYYY' } }"
                      mode="range"
                      v-model="calendarCheckq"
                    />
                  </vs-row>
                   <vs-row class="row-form" vs-w="12" >
                       <span style="font-size: 0.9rem; color: rgb(116, 103, 239);" >รวมวันซักอีก {{washingDate}} วัน ({{ format_ddmmyyy(addDay( calendarCheckq.end , washingDate)) }}) </span>

                  </vs-row>

              </div>
                </vs-col>

              </vs-row>
              <!--  ต้องการเช่า -->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                  style="z-index : 10 !important;"
                >ต้องการเช่า</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="2"
                  vs-sm="2"
                  vs-xs="2"
                  style="z-index : 10 !important;"
                >
                  <vs-input v-model="reservedq[qindex]" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="4"
                >
                  <vs-button
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                    style="width:100%; padding-left:0; padding-right:0; margin-left:20px "
                    color="rgb(255, 114, 131)"
                    type="filled"
                    @click="checkQueue(checkitem, qindex)"
                    class="px-2"
                  >
                    <span style=" vertical-align:middle;">เช็คคิว</span>
                  </vs-button>
                </vs-col>


              </vs-row>

              <!-- แสดงผลการเช็คคิว -->
               <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                  style="z-index : 10 !important;"
                ></vs-col>
                 <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="7"
                  vs-sm="7"
                  vs-xs="7"
                >
                  <div
                    v-if="checkqq[qindex] === 'ไม่ติด'"
                    class="p-4  grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%; background-color:#7ED3212B;"
                  >
                    <h3 style="font-family:mitr;color: #7ED321;" >ไม่ติดคิว</h3>
                  </div>
                  <div
                    v-if="checkqq[qindex] === 'ติด'"
                    class="p-4 grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100% ;background-color:#FCE6E5;"
                  >
                    <h3 style="font-family:mitr; color: #EA5657; " >ติดคิว</h3>
                  </div>
                   <div
                    v-if="checkqq[qindex] === 'ไม่มีของ'"
                    class="p-4 grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100% ;background-color:#fff3d3;"
                  >
                    <h3 style="font-family:mitr; color: #faaf24; " >ไม่มีสินค้าในสาขา</h3>
                  </div>
                  

                </vs-col>
               </vs-row>

              <!-- แสดงผล -->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="10"
                  vs-sm="10"
                  vs-xs="10"
                  class="form-label px-2"
                >
                  <div
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <div v-if=" checkitem['dlist'].length === 0">
                      <h4 style="font-family:mitr; color:#FF7283;">ไม่มีคิวเช่า</h4>
                    </div>
                    <div v-else>
                      <h4 class="pb-4" style="font-family:mitr; color:#7467EF;">คิวเช่า</h4>
                      <!-- รายการคิวเช่าทั้งหมด -->
                      <div v-for="(itm, keyy) in checkitem['dlist']" :key="keyy">
                        <div>
                            {{seq(keyy)}} <span class="label-btn-blue p-1">{{getOrderStatus(itm['orderHeaderId'])}}</span>
                            <span> {{getCustomerName(itm['orderHeaderId'])}} </span>

                            <span
                              style="color:blue; " class="pl-2"
                            >({{ itm['reservedQuantity'] }} ชุด)
                            </span>

                            <span @click="openTabTo(itm['orderHeaderId'])"
                              style="cursor:pointer; color:#0069ff !important" class="pl-2"
                            >{{getOrderNumber(itm['orderHeaderId']) }} 
                            </span>
                        </div>

                        <div class="pt-1 pl-6" v-if="itm['wnfRemark'] !== null && itm['wnfRemark'] !== undefined && itm['wnfRemark'] !== ''">
                            <span style="color:#ff782e; font-weight:300; font-size:0.8rem;" >Note: {{itm['wnfRemark']}}</span>
                        </div>

                        <div class="pt-1 pb-3 pl-6">
                            ตั้งแต่
                            <span
                              style="color:green;  "
                            >{{ format_ddmmyyy(itm['startDate']) }}</span>
                            ถึง
                            <span
                              style="color:red;  "
                            >{{ format_ddmmyyy(itm['wnfReceivingDate']) }}</span>
                        </div>


                        <div></div>

                      </div>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>

        <!--------------------------------------------------------------------->
        <!-- <div style="border-top: 1px solid #E3E3E3; margin-bottom:30px; "></div> -->
        <!--------------------------------------------------------------------->

        <!--  ปุ่ม -->
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          style="padding : 20px 20px; background-color: #F8F8F8; border: 0.5px rgb(248, 248, 248);"
        >
          <vs-button
            style="background-color:#4A4A4A !important; width:120px;"
            @click="closeCheckqueuePanel()"
            type="filled"
          >ยกเลิก</vs-button>
        </vs-row>
        <!--------------------------------------------------------------------->
      </vs-popup>
      <!-- POP UP -->
    </template>

    <!-- POP UP VIEW ORDER HISTORY -->
    <template>
      <vs-popup
        classContent="popup-order-history"
        title="ประวัติการเช่าสินค้า"
        :active.sync="orderHistoryPanelActive"

      >
        <!-- ROW OF CHECKQUEUE -->
        <vs-row
          
          style="display: flex;width: 100%;padding: 20px 0px;margin-bottom: 30px;border-bottom: 1px solid #e4e4e4;"
          vs-w="12"
          v-if="historyItem !==null && historyItem != undefined"
        >

          <!-- PRODUCT IMAGE [COL-3]  -->
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="3"
          >
            <div style="vertical-align: text-top;">
              <img v-if="historyItem !==null && historyItem != undefined"
                class="responsive rounded"
                :src="getFullpath(historyItem.mainImage)"
                alt="user-upload"
                style="width:100px;"
              />
            </div>
          </vs-col>
          <!-- DETAIL [COL-9]  -->
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="9"
            vs-sm="9"
            vs-xs="9"
          >
            <div
              class="grid-layout-container alignment-block"
              style="vertical-align:text-top ;width:100%;"
            >
              <!--  ข้อมูลสินค้า -->
              <vs-row class="row-form" vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"

                >ข้อมูลสินค้า</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="9"
                  vs-sm="9"
                  vs-xs="9"
                >
                  <div
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <vs-row vs-w="12">
                      <b v-if="historyItem !==null && historyItem != undefined" >{{historyItem.name}}</b>
                    </vs-row>
                    <vs-row
                      vs-w="12"
                      style="font-size: 11px;"
                      v-if="historyItem !==null && historyItem != undefined"
                    >{{historyItem.code}} (สาขา{{thisBranch.branchName}})</vs-row>
                  </div>
                </vs-col>
              </vs-row>


      

              <!-- แสดงรายการที่มีคิวเช่าอยู่ -->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="10"
                  vs-sm="10"
                  vs-xs="10"
                  class="form-label px-2"
                >
                  <div
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <!-- <div v-if="orderHistoryList.length === 0">
                      <h4 style="font-family:mitr; color:#FF7283;">ไม่มีคิวเช่า</h4>
                    </div> -->
                    <!-- <div v-else> -->
                    <div >
                      <h4 class="pb-4" style="font-family:mitr; color:#7467EF;">ประวัติการเช่า </h4>
                      <!-- รายการคิวเช่าทั้งหมด -->
                      <div  class="pt-6" v-for="(hisItem, ohind) in orderHistoryList" :key="ohind">
                        <div>
                            {{seq(ohind)}} <span class="label-btn-blue p-1">{{hisItem.orderHeader.orderStatus}}</span>
                            <span 
                            @click="openTabTo(hisItem.orderHeader['orderHeaderId'])"
                            style="cursor:pointer; color:#0069ff !important" class="pl-2"
                          >{{hisItem.orderHeader.orderNumber }} 
                          </span>
                            <br>
                            <span class="pt-1 pl-6"> {{hisItem.orderHeader.customerName}} </span>

                            <span
                              style="color:blue; " class="pl-2"
                            >( {{hisItem.reservedQuantity}} ชุด)
                            </span>

                          
                        </div>
                        <!-- 
                        <div class="pt-1 pl-6" v-if="itm['wnfRemark'] !== null && itm['wnfRemark'] !== undefined && itm['wnfRemark'] !== ''">
                            <span style="color:#ff782e; font-weight:300; font-size:0.8rem;" >Note: {{itm['wnfRemark']}}</span>
                        </div>
                        -->
                        <div class="pt-1 pb-3 pl-6">
                            ตั้งแต่
                            <span
                              style="color:green;  "
                            >{{ format_ddmmyyy(hisItem['startDate']) }}</span>
                            ถึง
                            <span
                              style="color:red;  "
                            >{{ format_ddmmyyy(hisItem['wnfReceivingDate']) }}</span>
                        </div>


                        <div></div>

                      </div>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>

        <!--------------------------------------------------------------------->
        <!-- <div style="border-top: 1px solid #E3E3E3; margin-bottom:30px; "></div> -->
        <!--------------------------------------------------------------------->

        <!--  ปุ่ม -->
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          style="padding : 20px 20px; background-color: #F8F8F8; border: 0.5px rgb(248, 248, 248);"
        >
          <vs-button
            style="background-color:#4A4A4A !important; width:120px;"
            @click="closeOrderHistoryPanel()"
            type="filled"
          >OK</vs-button>
        </vs-row>
        <!--------------------------------------------------------------------->
      </vs-popup>
      <!-- POP UP -->
    </template>


    <!-- POP UP BORROW ITEM -->
    <!-- <template>
      <vs-popup classContent="popup-example" title="ขอยืมชุด" :active.sync="borrowPanelActive">
        <vs-row
          style="display: flex;width: 100%;padding: 20px 0px;margin-bottom: 30px;border-bottom: 1px solid #e4e4e4;"
          vs-w="12"
          v-for="(bitem, bindex) in borrowList"
          :key="bindex"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="3"
          >
            <div style="vertical-align: text-top;">
              <img
                class="responsive rounded"
                :src="getFullpath(bitem.mainImage)"
                alt="user-upload"
                style="width:100px;"
              />
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="9"
            vs-sm="9"
            vs-xs="9"
          >
            <div
              class="grid-layout-container alignment-block"
              style="vertical-align:text-top ;width:100%;"
            >
              <vs-row class="row-form" vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >ข้อมูลสินค้า</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="9"
                  vs-sm="9"
                  vs-xs="9"
                >
                  <div
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <vs-row vs-w="12">
                      <b>{{bitem.name}}</b>
                    </vs-row>
                    <vs-row vs-w="12" style="font-size: 11px;">{{bitem.code}}</vs-row>
                  </div>
                </vs-col>
              </vs-row>
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >สาขาที่ต้องการยืม</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="5"
                  vs-sm="5"
                  vs-xs="5"
                  style="z-index : 20 !important;"
                >
                  <selection1
                    @result="selection1_result(bindex, ...arguments)"
                    :list="otherBranches"
                    id="branchId"
                    desc="branchName"
                  ></selection1>
                </vs-col>
              </vs-row>

              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >จำนวนในสต็อค</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="5"
                  vs-sm="5"
                  vs-xs="5"
                  style="z-index : 20 !important;"
                >
                  <span
                    style="font-size:18px; font-family:mitr; color:#7ED321; "
                  >มีสินค้า {{theInStock[bindex] }} ชุด</span>
                </vs-col>
              </vs-row>

              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >ช่วงเวลา</vs-col>
                <vs-col
                class="pr-6"
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="7"
                  vs-sm="7"
                  vs-xs="7"
                  style="z-index : 20 !important;"
                >

                  <v-date-picker

                    style="width:100%;"
                    :locale="{ id: 'th', firstDayOfWeek: 2, masks: {  L: 'DD/MM/YYYY' } }"
                    mode="range"
                    v-model="calendarBorrow"

                  />
                </vs-col>
              
              </vs-row>

              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                  style="z-index : 10 !important;"
                >ต้องการเช่า</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="2"
                  vs-sm="2"
                  vs-xs="2"
                  style="z-index : 10 !important;"
                >
                  <vs-input v-model="reservedq[bindex]" />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="4"
                >
                  <vs-button
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                    style="width:100%; padding-left:0; padding-right:0; margin-left:20px "
                    color="rgb(255, 114, 131)"
                    type="filled"
                     @click="chkq(bitem, bindex)"
                    class="px-2"
                  >
                    <span style=" vertical-align:middle;">เช็คของ</span>
                  </vs-button>
                </vs-col>
               

              </vs-row>
              <vs-row>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                  style="z-index : 10 !important;"
                ></vs-col>
                <vs-col
                  v-if="checkRes !== null && checkRes !== undefined && checkRes.length > 0"
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="7"
                  vs-sm="7"
                  vs-xs="7"
                  class="py-2"
                > 
                

                  <div
                    v-if="checkRes[bindex].result === 1"
                    class="grid-layout-container alignment-block p-4"
                    style="vertical-align:text-top ;width:100%; background-color:#7ED3212B;"
                  >
                    <h4 style="color: #7ED321;" >{{checkRes[bindex].desc}}</h4>
                  </div>
                  <div
                    v-if="checkRes[bindex].result === 0"
                    class="grid-layout-container alignment-block p-4"
                    style="vertical-align:text-top ;width:100%; background-color:#FCE6E5;"
                  >
                    <h4 style="color: #EA5657;" >{{checkRes[bindex].desc}}</h4>
                  </div>  

                </vs-col>
                <vs-col
                  v-else
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="7"
                  vs-sm="7"
                  vs-xs="7"
                ></vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>

        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          style="padding : 20px 20px; background-color: #F8F8F8; border: 0.5px rgb(248, 248, 248);"
        >
          <vs-button
              class="btn-success"
              style="width:150px; margin-right: 20px;"
              v-if="(borValid === 1 && checkRes[0] !== null && checkRes[0] !== undefined && checkRes.length > 0) && (checkRes[0].result === 1)"
                      @click="submitBorrow(blist[0], 0)"
                    >กดเพื่อยืมชุด</vs-button>
          <vs-button
            style="background-color:#4A4A4A !important; width:120px;"
            @click="closeBorrowPanel()"
            type="filled"
          >ยกเลิก</vs-button>
       
        </vs-row>
      </vs-popup>
    </template> -->
    <!-- HEADER -->
    <!-- BUTTON BORROW CHECKQUEUE ADDITEM -->
    <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="12"
        vs-sm="12"
        vs-xs="12"
        class="px-2"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
            class="px-2"
          >
            <v-date-picker
              style="width:100%;font-size:14px;"
              :locale="{ id: 'th', firstDayOfWeek: 2, masks: {  L: 'DD/MM/YYYY' } }"
              mode="range"
              v-model="calendarData"
            />
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="8"
            vs-sm="8"
            vs-xs="12"
            class="px-2"
          >
            <vs-row vs-w="12">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="12"
                class="px-2"
              >
                <vs-button
                  v-if="$acl.check('admin')"
                  vs-type="flex"
                  vs-align="center"
                  vs-justify="center"
                  style="width:100%; padding-left:0; padding-right:0; "
                  color="#7ED321"
                  type="filled"
                  v-on:click="newItem()"
                  :disabled="!btnActive"
                >
                  <span>
                    <i
                      style="font-size:18px; margin-right:5px;vertical-align:middle;"
                      class="material-icons"
                    >redeem</i>
                  </span>
                  <span style=" vertical-align:middle;">เพิ่มสินค้า</span>
                </vs-button>
              </vs-col>
              <!-- ปุ่มเช็คคิวสินค้า -->
              <vs-col
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="12"
                class="px-2"
              >
                <!-- ปุ่มเช็คคิวสินค้า -->
                <vs-button
                  vs-type="flex"
                  vs-align="center"
                  vs-justify="center"
                  style="width:100%; padding-left:0; padding-right:0; "
                  color="rgb(255, 114, 131)"
                  type="filled"
                  @click="checkqueueListPanel()"
                  :disabled="!btnActive"
                >
                  <span>
                    <i
                      style="font-size:18px; margin-right:5px;vertical-align:middle;"
                      class="material-icons"
                    >update</i>
                  </span>
                  <span style=" vertical-align:middle;">เช็คคิวสินค้า</span>
                </vs-button>
              </vs-col>
              <!-- /ปุ่มเช็คคิวสินค้า -->

              <!-- ปุ่มสร้างการ์ดสินค้า -->
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-lg="3"
                vs-sm="3"
                vs-xs="12"
                class="px-1"
              >
                <vs-button
                  @click="createCatalog()"
                  vs-type="flex"
                  vs-align="center"
                  vs-justify="center"
                  style="width:100%; padding-left:0; padding-right:0; "
                  color="#9B9B9B"
                  type="filled"
                  :disabled="!btnActive"
                >
                  <span>
                    <i
                      style="font-size:18px; margin-right:5px;vertical-align:middle;"
                      class="material-icons"
                    >local_offer</i>
                  </span>
                  <span style=" vertical-align:middle;">สร้างการ์ดสินค้า</span>
                </vs-button>
              </vs-col>

              <!-- /ปุ่มสร้างการ์ดสินค้า -->
              <!-- RELOAD BUTTON -->
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="3"  vs-xs="12" class="px-1">
                <vs-button
                  vs-type="flex"
                  vs-align="center"
                  vs-justify="center"
                  style="width:100%; padding-left:0; padding-right:0; "
                  color="#F5A623"
                  type="filled"
                  @click="newload()"
                  :disabled="!btnActive"
                >
                  <span>
                    <i
                      style="font-size:18px; margin-right:5px;vertical-align:middle;"
                      class="material-icons"
                    >cloud_download</i>
                  </span>
                  <span style=" vertical-align:middle;">reload</span>
                </vs-button>
              </vs-col>
            </vs-row>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <!-- BRANCHES -->

    <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="10"
        vs-sm="10"
        vs-xs="10"
        style="margin-top:20px;"
      >

      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="2"
        vs-sm="2"
        vs-xs="12"
      ></vs-col>
    </vs-row>

    <!-- BRANCHES -->
    <!-- PAGES  จำนวนข้อมูลที่แสดง  -->
    <vs-row
      class="row-form pb-2"
      vs-type="flex"
      vs-justify="flex-end"
      vs-w="12"
      style="margin-top:10px; margin-bottom:10px;"
    >

      <!-- แสดงเฉพาะสาขา แสดงสินต้าทั้งหมด -->
      <!-- <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="1"
        vs-sm="1"
        vs-xs="3"
        class="form-label px-2"
      >
        <v-select
          style="width: 100%;"
          v-model="pageitemSelected"
          :options="pageitemOptions"
          :select="pageitemSelected"
        ></v-select>
      </vs-col> -->

      <!-- lg-5 -->
      <vs-col
        vs-type="flex "
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="5"
        vs-sm="5"
        vs-xs="12"
        class="form-label px-2"
      >
      <v-select
          style="width:30%;"
          v-model="branchDisplaySelected"
          :options="branchDisplayOption"
          :select="branchDisplaySelected"
          class="mr-2"
        ></v-select>
        <div>
        ลำดับ :
        <div @click="itemOrder('createdatetime')" :class="[sorting1==='createdatetime'?'order-btn-active':'']" class="order-btn"   >สินค้าล่าสุด</div>
        <div @click="itemOrder('beststat')" :class="[sorting1==='beststat'?'order-btn-active':'']" class="order-btn"   >สถิติเช่าสูงสุด</div>
        <div @click="itemOrder('price')" :class="[sorting1==='price_desc'||sorting1==='price_asc'?'order-btn-active':'']" class="order-btn"   >ราคาสินค้า
           <i
           v-if="sorting1==='price_desc'"
              style="font-size:16px; font-weight:bold; margin-right:5px;vertical-align:middle;  border-radius:10px; "
              class="material-icons"
            >arrow_downward</i>
            <i
           v-if="sorting1==='price_asc'"
              style="font-size:16px; font-weight:bold; margin-right:5px;vertical-align:middle;  border-radius:10px; "
              class="material-icons"
            >arrow_upward</i>
        </div>
      </div>

        <!-- <div class = "grid-layout-container alignment-block " style = "width:100%;" >
          <vs-row vs-w="12">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="12"  vs-sm="12" vs-xs="12" class="px-2" >
                    Selected : {{newnumber}}
              </vs-col>
          </vs-row>
          <vs-row vs-w="12">
              <vs-col class="py-2 px-2 clear-btn" style="cursor:pointer; font-size: 0.7rem; color:red;" vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-lg="12"  vs-sm="12" vs-xs="12"  >
                   <span @click="removeSelected()" >เคลียร์ที่เลือกไว้</span>
              </vs-col>
          </vs-row>
        </div> -->

      </vs-col>


      <!-- Filter Buttom -->
      <!-- lg-2 -->
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="2"
        vs-sm="2"
        vs-xs="6"
        class="form-label px-2"
      >
                <vs-button
                  vs-type="flex"
                  vs-align="center"
                  vs-justify="center"
                  style="width:100%; padding-left:0; padding-right:0; "
                  color="dark"
                  type="border"
                  v-on:click="filterData()"
                  :disabled="!btnActive"
                >
                  <span v-if="checkSearchParam()" >
                    <i
                      style="font-size:18px;vertical-align:middle;"
                      class="material-icons"
                    >filter_lists</i>
                  </span>
                  <span style=" vertical-align:middle;">Filter</span>&nbsp;
                </vs-button>
      </vs-col>

      <!-- เคลียร์ข้อมูลค้นหา -->
      <!-- lg-2 -->
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="2"
        vs-sm="2"
        vs-xs="6"
        class="form-label px-2"
      >
      <vs-button type="border" style="font-size: 0.85rem;" color="danger" @click="clearSeachParams()">เคลียร์การค้นหา</vs-button>

      </vs-col>

       <!-- ส่งดำเนินการ -->
       <!-- lg-2 -->
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="2"
        vs-sm="2"
        vs-xs="6"
        class="form-label px-2"
      >
      <vs-button type="border"  v-if="$store.state.auth.currentPackage ==='m' || $store.state.auth.currentPackage ==='l'"  @click="openSendMultipleItem()">ส่งดำเนินการ</vs-button>

       <vs-button type="border" disabled  v-else-if="$store.state.auth.currentPackage ==='s'" >ส่งดำเนินการ</vs-button>

      </vs-col>


      <!-- PAGE SIZE -->
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="1"
        vs-sm="1"
        vs-xs="3"
        class="form-label px-2"
      >
        <v-select
          style="width: 100%;"
          v-model="pageitemSelected"
          :options="pageitemOptions"
          :select="pageitemSelected"
        ></v-select>
      </vs-col>


    </vs-row>
    <!--  PAGES -->

    <!-- HEADER -->
    <!-- PAGINATION -->
        <!-- PAGES  จำนวนข้อมูลที่แสดง  -->
    <vs-row
      vs-type="flex"
      vs-w="12"
   >

  <!-- เคลียร์ที่เลือกไว้ selected -->
   <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="6"
        vs-sm="6"
        vs-xs="6"
      >

      <div class = "grid-layout-container alignment-block " style = "width:100%;" >
          <vs-row vs-w="12">
              <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-lg="12"  vs-sm="12" vs-xs="12" class="px-2" >
                    Selected : {{newnumber}}
              </vs-col>
          </vs-row>
          <vs-row vs-w="12">
              <vs-col class="py-2 px-2 clear-btn" style="cursor:pointer; font-size: 0.7rem; color:red;" vs-type="flex" vs-justify="flex-start" vs-align="flex-end" vs-lg="12"  vs-sm="12" vs-xs="12"  >
                   <span @click="removeSelected()" >เคลียร์ที่เลือกไว้</span>
              </vs-col>
          </vs-row>
        </div>



      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-lg="6"
        vs-sm="6"
        vs-xs="12"
      >
        <vs-pagination style="display: block !important;" :total="pageNumber" v-model="currentx" goto></vs-pagination>

      </vs-col>
    </vs-row>

    <!-- TABLE -->
    <vs-table  :sst="true" @change-page="handleChangePage" style="z-index:1;" :data="itemsInStock">
      <!-- TABLE HEADER -->
      <template style="z-index:1 !important;" slot="thead">
        <vs-th flex-type="flex" flex-justify="center">
          <feather-icon
            v-if="selectStatus===0"
            v-model="selectAll"
            @click="selectedAll($event)"
            icon="SquareIcon"
            svgclasses="w-4 h-4 stroke-current text-danger"
            :badge="newnumber"
          ></feather-icon>

          <feather-icon
            v-else-if="selectStatus===1"
            @click="selectedAll($event)"
            icon="CheckSquareIcon"
            svgClasses="stroke-current text-primary"
            :badge="newnumber"
          ></feather-icon>
          <feather-icon
            v-else-if="selectStatus===2"
            @click="selectedAll($event)"
            icon="MinusSquareIcon"
            svgClasses="stroke-current  text-primary"
            :badge="newnumber"
          ></feather-icon>

          <!-- <feather-icon
            v-else-if="selectStatus===2"
            @click="selectedAll($event)"
            icon="MinusSquareIcon"
            svgClasses="stroke-current  text-primary"
            :badge="checkSelectedStatus()"
          ></feather-icon>-->
        </vs-th>
        <vs-th sort-key="incharge">รูปตัวอย่าง</vs-th>
        <vs-th sort-key="orderNumber">สินค้า</vs-th>
        <!-- <vs-th sort-key="productItem">ชื่อสินค้า</vs-th> -->
        <vs-th sort-key="customerName">จำนวน</vs-th>
        <vs-th sort-key="rentalTotal">ราคาเช่า/ชิ้น</vs-th>
        <vs-th sort-key="bail">ค่าประกัน/ชิ้น</vs-th>
        <!-- <vs-th sort-key="pickupDate">ประเภท</vs-th>
        <vs-th sort-key="returnDate">สี</vs-th>-->
        <vs-th sort-key="returnDate">วันที่เพิ่ม</vs-th>
        <vs-th sort-key="rentalStat">
          สถิติ
          <br />การเช่า
        </vs-th>
        <vs-th v-if="$acl.check('admin')" sort-key="itemStatus">สถานะ</vs-th>
        <vs-th v-if="$acl.check('admin')">การจัดการ</vs-th>
      </template>

      <!-- TABLE BODY -->
      <template style="z-index:1 !important;" slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>

            <feather-icon
              :data-id="tr.productItemId"
              v-if="data[indextr].selected===true"
              @click="addSelectedList(tr, $event)"
              icon="CheckSquareIcon"
              svgClasses="stroke-current text-primary"
            ></feather-icon>
            <feather-icon
              :data-id="tr.productItemId"
              v-else
              @click="addSelectedList(tr, $event)"
              icon="SquareIcon"
              svgClasses="stroke-current"
            ></feather-icon>
          </vs-td>
          <vs-td>
            <img
              @click="zoomimage(tr.mainImage)"
              class="responsive rounded"
              :src="getFullpath(tr.mainImage)"
              alt="user-upload"
              style="width:70px;"
            />
          </vs-td>
          <!-- itemName -->
          <vs-td style=" width:200px;">
            <span
              @click="editItem($event, tr.productGroupId)"
              style="color: #2372CE; font-weight:bold;"
            >{{tr.name}}</span>
            <br />
            <b>ประเภท :</b>
            {{getCategoryName(tr.category)}}
            <br />
            <!-- &nbsp;&nbsp;&nbsp; -->
            <b>สี :</b>
            {{getColorName(tr.color)}}
            <br />
            <!-- &nbsp;&nbsp;&nbsp; -->
            <b>ไซส์ :</b>
            {{getSizeName(tr.size)}}
            <br />
            <b>รหัส: </b>
            <b v-clipboard:copy="tr.code" v-clipboard:success="onCopy" v-clipboard:error="onError">{{getBarcode(tr.code)}}</b>
            <!-- <b>{{getBarcode(tr.code)}}</b> -->

            <!-- แบ่งแยกกลุ่ม -->
            <div
              class="grid-layout-container alignment-block"
              style="vertical-align:text-top ;width:100%;"
            >
              <vs-row vs-w="12">
                <vs-col
                  v-if="$acl.check('admin')"
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <!-- <div
                    @click="addGroup(tr.groupId, tr.productItemId)"
                    class="sm-btn-cyan"
                  >เพิ่มกลุ่ม</div>-->
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                  v-if="tr.group === 'c'"
                >
                  <div class="sm-btn-black">c</div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                  v-if="tr.group === 'm'"
                >
                  <div class="sm-btn-yellow">m</div>
                </vs-col>
              </vs-row>
            </div>

            <!-- แบ่งแยกกลุ่ม -->

            <br />
            <!-- <i
              @click="singlePrintPDF($event,tr)"
              style="font-size:20px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; color:#5A8EE6; "
              class="material-icons iconhover"
            >local_offer</i> -->
            <!-- ICON for check queue -->
             <vx-tooltip  text="เช็คคิวสินค้ารายการนี้" style="width:fit-content !important; display:inline-block;" >
              <i v-if="tr.branchStock[0].branchId !== null && tr.branchStock[0].branchId !== undefined"
                @click="checkqueuePanel($event,tr,indextr)"
                style="font-size:20px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; color:#5A8EE6; "
                class="material-icons iconhover"
              >update</i>
            </vx-tooltip>
            <!-- ICON for borrow -->
          <!-- <vx-tooltip v-if="getCurrentPackage !== 's'" text="ขอยืมชุด" style="width:fit-content !important; display:inline-block;" >
            <i
              @click="borrowPanel($event,tr)"
              style="font-size:20px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; color:#5A8EE6; "
              class="material-icons iconhover"
            >repeat</i>
            </vx-tooltip> -->
          </vs-td>
          <vs-td style="text-align:center; font-family: SukhumvitSet-Medium;">
            <b v-if="$acl.check('onlyStaff')">{{mybranchstock(tr.productItemId)}}</b>
            <b v-if="$acl.check('admin')">{{tr.inStock}}</b>
            <br />

            <div v-if="$acl.check('admin')">
              <!-- <i
                @click="assignItemPanel($event,indextr,tr)"
                style="font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; color:#5A8EE6; "
                class="material-icons iconhover"
              >store_mall_directory</i> -->
              <vx-tooltip text="ลงสินค้าตามสาขา">
              <i
                @click.stop="editData(tr,indextr)"

                style="font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; color:#5A8EE6; "
                class="material-icons iconhover"
              >store_mall_directory</i>
             </vx-tooltip>
            </div>
          </vs-td>

          <vs-td style="padding-left:30px;">{{tr.rentalPrice}}</vs-td>
          <vs-td style="padding-left:30px;">{{tr.bail}}</vs-td>

          <!-- <vs-td>{{tr.itemCategory}}</vs-td>
          <vs-td>{{tr.itemColor}}</vs-td>-->
          <!-- <vs-td>{{tr.orderStatus}}</vs-td> -->
          <vs-td style="width:90px;">{{formatDate(tr.updateDateTime)}}</vs-td>
          <vs-td   style="text-align: center;"><span style="font-size: 1.2rem; color: blue;  " @click="orderHistoryPanel($event,tr,indextr)">{{tr.rentalStat}}</span></vs-td>
          <vs-td v-if="$acl.check('admin')" style="text-align: center;">
            <vs-switch @change="changeState(indextr)" color="success" v-model="tr.state">
              <span slot="on">เปิด</span>
              <span slot="off">ปิด</span>
            </vs-switch>
          </vs-td>

          <vs-td v-if="$acl.check('admin')">
           <vx-tooltip text="แก้ไขข้อมูลสินค้า">
            <i
              @click="editItem($event, tr.productGroupId)"
              style="color: #5A8EE6;font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons"
            >edit</i>
          </vx-tooltip>
            <!-- <i
              @click="removeItem($event, indextr , tr)"
              style="color:#F63B39; font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons"
            >delete_forever</i>-->
          </vs-td>

          <div style="width: 100%; background-color:pink; height:50px; "></div>
        </vs-tr>
      </template>
    </vs-table>
    <!-- TABLE -->
    <!-- PAGINATION -->
    <div class="py-10">
      <vs-pagination style="display: block !important;" :total="pageNumber" v-model="currentx" goto></vs-pagination>
    </div>
    <br />
  </vx-card>
</div>

</template>

<script>

import { BEmbed } from 'bootstrap-vue'
import Multiselect from "vue-multiselect";
import Prism from "vue-prism-component";
import vSelect from "vue-select";
// import HotelDatePicker from "vue-hotel-datepicker";
import Datepicker from "vuejs-datepicker";
import shapeFormat from "../../mixins/shapeFormat.js";
// import ProductService from "./Services/ProductService";
import { setTimeout } from "timers";
import Selection1 from "./Components/Selection1";
import UpdateStockSidebar from './Components/UpdateStockSidebar.vue'
import FilterStockSidebar from './Components/FilterStockSidebar.vue'
import WashPanel from './WashPanel.vue'
import CatalogPanel from './CatalogPanel.vue'

// const productService = new ProductService();

var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//ต้องระบุตามชื่อของ ไฟล์ font
pdfMake.fonts = {
  Sukhumvit: {
    normal: "SukhumvitSet-Medium.otf",
    bold: "mitr.otf"
  },
  kanit: {
    normal: "Kanit-Medium.ttf",
    bold: "Kanit-Bold.ttf"
  },
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf"
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf"
  }
};

// import VueHotelDatepicker from "@northwalker/vue-hotel-datepicker";

export default {
  mixins: [shapeFormat],
  data() {
    return {
      showJustAvailable: true,
      branchDisplaySelected: "แสดงเฉพาะสาขา",
      branchDisplayOption: ["แสดงเฉพาะสาขา", "สินค้าทั้งหมด"],

      //*** 17Apr 2022 */
      sorting1: "createdatetime",
      //*** 23Dec2021
      catalog: [],
      catalogKey: 0,
      washingKey: 0,
      washingDate: 0,
      tutorialPopupActive: false,
      itemind: 0,
      updateStockKey: 0,
      filterStockKey: 0,
      productGroup: {},
      addNewDataSidebar: false,
      filterDataSidebar:false,
      sidebarData: {},
      filterSidebarData:{},
      newnumber: 0,
      theSelected: [],
      searchText: "",
      currentx: 1,
      pageNumber: 1,
      headerConfig: {},
      calendarData: {},
      calendarCheckq: {},
      calendarBorrow: {},
      branchrad: "",
      borValid: 0,
      checkRes: [],
      colorSelected: "",
      sizeSelected: "",
      borrowReq: [],
      borrowBranchId: "",
      branchRadio: [],
      otherBranches: [],
      firstOtherBranch: {},
      reservedq: [],
      checkqq: [],
      theInStock: [],
      name: "",
      name2: ["check1", "check2", "check3"],
      thisBranch: {},
      dlist: [],
      orders: [],
      // qPanelDlist: [],
      itemsInBranch: [],
      currentBranch: "", // BRANCH ID
      otherBranch: [],
      startingDateValue: new Date(),
      endingDateValue: null,
      theCheckIn: new Date(),
      theCheckOut: null,
      zoomPopupActive: false,
      zoomzoom: "",
      lastPage: 0,
      pageitemSelected: 16,
      pageitemOptions: [8, 16, 24, 32, 40, 48, 56, 64],
      removeId: "",
      removePopupActive: false,
      removeIndex: -1,
      removeCode: "",
      currentUser: "guest",
      // allBranchCheckbox: true,
      btnActive: true,
      panelId: "",
      categorySelected : "",
      categoryOption: [],
      categories: [],
      colors:[],
      sizes:[],
      code3Digit: "000",
      loadedlist: null,
      anewCheckIn: "2019-04-01",
      checkInDate: null,
      checkOutDate: null,
      checkq: "",
      tryselect: "เมืองทองธานี",
      maximumBorrowedItemAmount: 0,
      borrowedItemAmount: 0,
      selectedBranch: "เลือกสาขา",
      branchOptions: ["เมืองทองธานี", "ราชพฤกษ์"],
      selectedDate: {
        start: "",
        end: ""
      },
      remainingItems: 0,
      currentAmountInstock: 0,
      assignItems: [],
      assignItemPanelActive: false,
      indexToAssign: 0,
      borrowPanelActive: false,
      borrowList: [],
      blist: [],
      checkqueuePanelActive: false,
      checkqueueList: [],
      startDate: undefined,

      orderHistoryPanelActive: false,
      orderHistoryList:[],
      historyItem: null,
      instart: undefined,
      inend: undefined,
      startPoint: "2019-05-01",
      endPoint: "2019-05-03",
      checkqueueItem: {
        itemImage: "http://localhost:8088/images/item/defaultimage.png",
        // itemImage:
        //   "https://www.google.com/url?sa=i&source=images&cd=&cad=rja&uact=8&ved=2ahUKEwirvNWq2NLiAhWeiXAKHRt0Bb0QjRx6BAgBEAU&url=%2Furl%3Fsa%3Di%26source%3Dimages%26cd%3D%26ved%3D%26url%3Dhttps%253A%252F%252Fwww.allure.com%252Fstory%252Ffashion-nova-plus-size-controversy%26psig%3DAOvVaw1PNvitmeBB7YkiDCzMkVuq%26ust%3D1559836059922993&psig=AOvVaw1PNvitmeBB7YkiDCzMkVuq&ust=1559836059922993",
        itemName: "Item Name",
        AmountInStock: 0,
        rentalPrice: 0,
        bail: 0,
        itemCategory: "ไม่ระบุ",
        itemColor: "ไม่ระบุ",
        updateDate: "2019-04-23",
        rentalStat: 0,
        itemStatus: false
      },
      rentalQueue: [
        {
          customerName: "คุณเบน",
          startDate: "2019-04-23",
          endDate: "2019-04-25"
        },
        {
          customerName: "คุณจอน",
          startDate: "2019-05-12",
          endDate: "2019-05-15"
        }
      ],

      itemsInStock: [],

      branchCheckbox: ["ลาดพร้าว", "เมืองทองธานี"],
      branches: ["ลาดพร้าว", "เมืองทองธานี"],

      statusToReturn: "คืนชุดแล้ว",
      statusToReturnOption: [
        { text: "คืนชุดแล้ว", value: "คืนชุดแล้ว" },
        { text: "ยกเลิก", value: "ยกเลิก" }
      ],
      popupActive: false,
      searchDate: "",
      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: "เงินสด",
      payment_option: [
        { text: "เงินสด", value: "เงินสด" },
        { text: "โอนเงิน", value: "โอนเงิน" },
        { text: "Alipay/wechat", value: "Alipay/wechat" },
        { text: "บัตรเครดิต", value: "บัตรเครดิต" }
      ],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: [],

      //** สำหรับซักรีด */
      sendingReq: {},
      washPanel: false,
      sendingItems: [],
      washingShopList: [],
      // washingDate: null,
      washingShop: null,
      //** สำหรับซักรีด */
      infoNote: "",
      sendingAction: 0,
    };
  },
  computed: {

  //   dlist(){
  //    return this.$store.getters["washingList/orderDetail"];
  //  },


  //   orders(){
  //    return this.$store.getters["washingList/orderHeader"];
  //  },

   

      //  *** BEGIN : 2020-12-12 : Search By branches
    getCurrentPackage(){

        return this.$store.getters["auth/currentPackage"];
    },

     //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches(){
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },
    myPermission(){
      // console.log('@@@@PICKUP myPermission >>> ',this.$store.getters["auth/permission"]);

      return this.$store.getters["auth/permission"];
    },
     //  *** BEGIN : 2020-11-28 : Search By branches
    pdlist(){
      // console.log('this.$store.getters["productList/selected"] >>> ',this.$store.getters["productList/selected"]);

      return this.$store.getters["productList/selected"];
    },
    //  *** BEGIN : 2021-05-18 : Search By branches
    searchParam(){

      return this.$store.getters["productList/searchParameters"];
    },

    allBranchCheckbox: {
      get: function() {
        var all = true;

        if (this.branchCheckbox.length === this.branches.length) {
          all = true;
        } else {
          all = false;
        }

        return all;
      },
      set: function() {}
    }

  },

  async created() {
    // MODIFIED >>>>>>>>>>>>
    this.$vs.loading();
        // INIT productGroup
    // this.productgroupid = this.$route.params.productgroupid;
    // await this.$store.dispatch(
    //   "productGroup/set",
    //   this.productgroupid
    // );

    this.btnActive = false;

    // this.productGroup = await this.$store.getters["productGroup/productGroup"];

    this.calendarData.start = new Date();
    this.calendarData.end = this.addDay(new Date(), 1);

    this.calendarCheckq.start = new Date();
    this.calendarCheckq.end = this.addDay(new Date(), 1);

    // this.calendarBorrow.start = new Date();
    // this.calendarBorrow.end = this.addDay(new Date(), 1);
    var tk = localStorage.getItem("token");
    var theToken = JSON.parse(tk);
    this.headerConfig = {
      headers: {
        Authorization: "bearer " + theToken
      }
    };

    this.currentUser = JSON.parse(localStorage.getItem("username"));


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


   



    // LOAD BRANCH
    var responseBranch = null;
    try {
      responseBranch = await this.$http.get(
        this.$store.state.apiURL + "/api/branch",
        this.$store.state.auth.apiHeader
      );
    } catch (error) {
      responseBranch = null;
      this.shapeFormat.noticeError(error);
    }

    if (responseBranch.status !== null) {
      this.branches = await responseBranch.data.filter(x => x.state === true);
      // FIND OTHER BRANCH
      this.otherBranches = await responseBranch.data.filter(
        x =>
          x.state === true && x.branchId !== this.$store.state.auth.mybranchid
      );
      this.firstOtherBranch = await responseBranch.data.find(
        x =>
          x.state === true && x.branchId !== this.$store.state.auth.mybranchid
      );
    }

    

    var response = null;
    try {
      response = await this.$http.post(
        this.$store.state.apiURL + "/api/productitem/pagination",
        {
          T1: this.currentx,
          T2: this.pageitemSelected,
          T3: this.searchText,
          T12: this.getCurrentBranch.branchId,
          showJustAvailable: this.showJustAvailable, //*** true -> แสดงเฉพาะที่มีใน stock สาขานั้นๆ false -> จะมีหรือไม่แสดงหมด
            //*** 17Apr 2022 sorting with {createDateTime, beststat, price}
        },
        this.$store.state.auth.apiHeader
      );
      this.pageNumber = response.data.pageNumber;


    } catch (error) {
      response = null;
    }

    this.dlist = await this.loadAllDetailShare();
    

     // LOAD Stock
     var responseStock = null;
    try {
      responseStock = await this.$http.get(
        this.$store.state.apiURL + "/api/stock",
        this.$store.state.auth.apiHeader
      );
      // console.log("STOCK RESPONSEStock >>> ", responseStock.data);
    } catch (error) {
      responseStock = null;
      this.shapeFormat.noticeError(error);
      // appropriately handle the error
    }

    if (responseStock !== null) {
      this.thestock = await responseStock.data;
    }

    // console.log("this.thestock >>> ", this.thestock);


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    if (response !== null) {
      // this.noticeSuccess("ทำการบันทึกสำเร็จ");

      this.$vs.loading.close();
      this.itemsInStock = await response.data.itemList;
      this.originalList = await response.data.itemList;
      console.log("created() this.itemsInStock ",this.itemsInStock);

    } else {
      this.$vs.loading.close();
      this.noticeError("เกิดความผิดพลาดขึ้น");
    }

    // FIND CURRENT BRANCH ID !!
    // this.currentBranch = this.$store.state.auth.mybranchid;
    this.currentBranch = this.getCurrentBranch;

    // FIND OTHER BRANCH
    // this.otherBranch = this.branches.filter(
    //   br => br.branchId !== this.$store.state.auth.mybranchid
    // );

    // if (this.$store.state.auth.permission === "staff") {
    //   this.itemsInStock = await this.removeZero(this.itemsInStock);
    //   this.originalList = await this.removeZero(this.originalList);
    // }

    

     var setting = null;
    try {
        setting = await this.$http.post(
            this.$store.state.apiURL + "/api/shopinfo/get-clean-fix-duration",
            {},
            this.$store.state.auth.apiHeader
          );

          // console.log("setting >>> ",setting.data);

          this.washingDate = setting.data.cleanFixDuration;

    } catch (error) {
      // setting = null;

    }

    // ************ WASHING 2021-09-28

    await this.$store.dispatch(
      "productGroup/getAllInfo"
    );

    this.categories = this.$store.getters["productGroup/categories"];
    this.textures = this.$store.getters["productGroup/textures"];
    this.colors = this.$store.getters["productGroup/colors"];
    this.sizes = this.$store.getters["productGroup/sizes"];

    var shopRes = null;

    try {
      shopRes = await this.$http.post(
        this.$store.state.apiURL + "/api/v2/orderheader/clean-fix/getWnfShop",
        {},
        this.$store.state.auth.apiHeader
      );


      this.washingShopList = shopRes.data.itemList.filter(x=>x.status===true);
      this.$store.commit("washingList/SET_WNFSHOP",this.washingShopList);

    } catch (error) {
      shopRes = null;

    }

    // ************ WASHING 2021-09-28

    // await this.$store.dispatch("washingList/getOrderHeader");
    // await this.$store.dispatch("washingList/getOrderDetail");

    this.orders = await this.loadAllOrderShare();
    this.thisBranch =  this.getCurrentBranch
    this.branchrad = this.getCurrentBranch.branchId;



    // console.log('????????????/ IN STOCK PAGE ' ,this.$store.state.auth.currentBranch);


    // MODIFIED <<<<<<<<<<<<<<<<<
    this.checkSelected();

    this.btnActive = true;

  },

  async mounted() {
    // this.tutorialPopupActive = true;
    this.checkSelectedStatus();
  },

  methods: {

    async itemOrder(param){


      if(param === 'beststat'){
        this.sorting1 = "beststat";
      }else if(param === 'price'){
          if(this.sorting1 === "price_asc" ){
              this.sorting1 = "price_desc";
          }else{
              this.sorting1 = "price_asc";
          }

      }else{
          this.sorting1 = "createdatetime";
      }


      await this.newload();

    },

    createCatalog() {

      let selected  = this.$store.getters["productList/selected"];

      this.catalog = [];
      this.catalogKey++;

      for (let ind = 0; ind < selected.length; ind++) {
        selected[ind].qty = 1;
        this.catalog.push(selected[ind]);

      }

      // this.catalog.unshift(...selected);

      if(this.catalog.length <= 0){
        this.$swal({
          icon: 'warning',
          title: 'ยังไม่ได้เลือกสินค้า',
          text: "กรุณาเลือกสินค้าอย่างน้อย 1 ชิ้น",
        });
      }else{
         this.$store.commit("productList/UPDATE_CATALOG",this.catalog);
         this.$store.commit("productList/OPEN_CATALOG_PANEL",true);

        //  this.sendingAction = action;
      }


      console.log("#STOCK createCatalog >>> ",this.catalog);


    },




    openSendMultipleItem(){

      this.washingKey++;
      this.sendingItem = [];
      let total = 0

      this.pdlist.forEach( x => {

          this.sendingItem.push(x);
          total++;

      });

      // console.log("this.sendingItems >>> ",this.sendingItems);

      if(total <= 0){
        this.$swal({
          icon: 'warning',
          title: 'ท่านยังไม่ได้เลือกสินค้า',
          text: "กรุณาเลือกสินค้า (สถานะ: รอส่งซัก/ซ่อม) อย่างน้อย 1 ชิ้น",
        });
      }else{
         this.$store.commit("washingList/ADD_SENDING_ITEM",this.sendingItem);
         this.$store.commit("washingList/UPDATE_PANEL",true);
        //  this.sendingAction = action;
      }



    },


    async closeSendItem() {
        this.sendingReq = {};
        // this.washPanel = false;
        this.$store.commit("washingList/UPDATE_PANEL",false);
        this.sendingItems = [];
        this.washingDate = null;
        this.washingShop = null;
        this.infoNote = "";
        this.sendingAction = 0;

        this.removeSelected();
        await this.newload();

    },

    // async sendingToWash(){

    //   //*** VALIDATE ***/

    //   if(  (this.sendingAction === 1) &&  ( this.washingDate === null ||  Object.entries(this.washingDate).length <= 0 ) ){
    //       this.$swal({
    //         icon: 'warning',
    //         title: 'กรุณาเลือกวันที่ในการส่งซัก',
    //       });

    //       return;
    //   }
    //   if(  (this.sendingAction === 1) &&  ( this.washingShop === null || this.washingShop === undefined ||  Object.entries(this.washingShop).length <= 0 ) ){
    //       this.$swal({
    //         icon: 'warning',
    //         title: 'กรุณาเลือกร้านที่ต้องการส่งซัก',
    //       });

    //       return;
    //   }

    // if( this.sendingItems.length <= 0)
    // {
    //   this.$swal({
    //         icon: 'warning',
    //         title: 'กรุณาชุดที่ต้องการส่งอย่างน้อย 1 ชุด',
    //       });
    // }



    //   //*** TRANSFORM ***/


    //   // console.log("washingShop >>> ",this.washingShop)


    //   // console.log("wnfOrderArray >>> ",wnfOrderArray)

    //   this.btnActive = !this.btnActive;
    //   // this.$vs.loading();


    //    let req = {

    //           };

    //   let wnfOrderArray = this.automapper(this.sendingItems , ["orderDetailId"]);


    //   //*** ส่งซัก */
    //   if(this.sendingAction === 1)
    //   {
    //     req = {
    //               sendingDate: this.formatDate(this.washingDate.start),
    //               receivingDate: this.formatDate(this.washingDate.end),
    //               remark: this.infoNote,
    //               branchId: this.getCurrentBranch.branchId,
    //               wnfShopId: this.washingShop.wnfShopId,
    //               wnfOrderDetails: wnfOrderArray
    //           };
    //     await this.$store.dispatch("washingList/sendToWash", req);
    //   }
    //   //*** รับชุดซักกลับ */
    //   else if(this.sendingAction === 2)
    //   {
    //     req = {
    //             wnfOrderId : this.sendingItems[0].wnfOrderId
    //           };
    //     await this.$store.dispatch("washingList/receiveFromWash", req);
    //     this.removeSelected();
    //   }
    //   //*** ไม่ส่งซัก */
    //   else if(this.sendingAction === 3){

    //     await this.$store.dispatch("washingList/notToSend", wnfOrderArray);
    //     this.removeSelected();
    //   }

    //   this.closeSendItem();

    //   this.btnActive = !this.btnActive;


    //   this.newload();

    //   // this.$vs.loading.close();

    //   // this.washingDate = null;
    //   // this.washingShop = null;
    //   // this.infoNote = "";


    // },


    clearSeachParams(){
      this.$store.dispatch('productList/clearParm');
      this.newload();
    },
    clearParams(){
      this.$store.dispatch();
    },
    checkSearchParam(){

      if(this.searchParam !== null && this.searchParam !== undefined)
        {
          if(Object.entries(this.searchParam).length > 0){
            return true;
          }
        }
      else
        return false;
    },
    removeSelected(){

        this.$store.dispatch("productList/clearAll");
        this.selectedList = [];
        this.newnumber = 0;
        this.selectStatus = 0;

        this.$store.commit("washingList/REMOVE_SENDING_ITEM");
        this.$store.commit("washingList/UPDATE_PANEL",false);

        this.itemsInStock.forEach(element => {
            element.selected = false;
        });
        // console.log('REMOVE CLEAR ALL ');
        // console.log('getter >>>> ',this.$store.getters["productList/selected"]);


    },

    // ************** FOR SIDE BAR **************
    async InStockValue(newvalue, newvalue_ind){
      console.log('Data saved and new value is >>> ',newvalue);
      console.log('Data saved and new index is >>> ',newvalue_ind);
      this.itemsInStock[newvalue_ind].inStock = newvalue;
      this.$set(this.itemsInStock , newvalue_ind ,this.itemsInStock[newvalue_ind] );

      await this.reload();


    },

    async SearchParameterValue(){

      // console.log("this.searchParam" , this.searchParam);

      this.currentx = 1;

      // active: false
      // category: "M"
      // color: "B"
      // hotItem: false
      // newArrival: false
      // onWeb: false
      // searchText: "hello"
      // size: null
      // texture: "PP"


      this.newload();



    },

    toggleFilterDataSidebar(val=false) {
      this.filterDataSidebar = val
      console.log('SIDEBAR CLOSED ');
    },
    filterData() {
      this.filterSidebarData  = {};
      // let data = Object.assign({}, pg);
      // this.sidebarData = data;
      // this.itemind = ind;
      (this.filterStockKey)++;

      this.toggleFilterDataSidebar(true)
    },


    toggleDataSidebar(val=false) {
      this.addNewDataSidebar = val
      console.log('SIDEBAR CLOSED ');
    },


    editData(pg,ind) {
      this.sidebarData  = {};
      let data = Object.assign({}, pg);
      this.sidebarData = data;
      this.itemind = ind;
      (this.updateStockKey)++;

      this.toggleDataSidebar(true)
    },


    addNewData() {
      this.sidebarData = {}
      this.toggleDataSidebar(true)
    },

    // ************** FOR SIDE BAR **************
    async selectedAll() {
      this.checkSelectedStatus();
      var newlist = [];
      Object.assign(newlist, this.itemsInStock);

      if (this.selectStatus === 0 || this.selectStatus === 2) {
        await this.$store.dispatch("productList/selectedAll", newlist);
      } else {
        await this.$store.dispatch("productList/unSelectedAll", newlist);
      }
      this.checkSelectedStatus();
      this.checkSelected();
    },
    async checkSelected() {
      event.stopPropagation();
      var selected = [];
      // var selected = this.$store.getters["productList/selected"];
      Object.assign(selected, this.$store.getters["productList/selected"]);

      for (let i = 0; i < this.itemsInStock.length; i++) {
        this.itemsInStock[i].selected = false;
      }

      selected.forEach(item => {
        var indextt = this.itemsInStock.findIndex(
          x => x.productItemId === item.productItemId
        );

        if (indextt !== null && indextt !== undefined && indextt !== -1) {
          this.itemsInStock[indextt].selected = true;
        }
      });

      Object.assign(this.selectedList, selected);
    },
    checkSelectedStatus() {
      var selected = [];
      // var selected = this.$store.getters["productList/selected"];
      Object.assign(selected, this.$store.getters["productList/selected"]);

      var itemlist = [];
      Object.assign(itemlist, this.itemsInStock);

      var thisPageCount = 0;
      selected.forEach(item => {
        var chk = itemlist.filter(x => x.productItemId === item.productItemId);
        if (chk.length > 0) {
          ++thisPageCount;
        }
      });
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      // console.log("                                               ");
      // console.log("itemlist.length >>>>>>>> ", itemlist.length);
      // console.log("thisPageCount >>>>>>>> ", thisPageCount);
      // console.log("selected.length >>>>>>>> ", selected.length);
      // console.log("***********************************************");

      this.newnumber = selected.length;

      if (thisPageCount <= 0) {
        this.selectStatus = 0;
        this.selectAll === false;
      } else {
        this.selectAll === true;
        if (thisPageCount === itemlist.length) {
          this.selectStatus = 1;
        } else {
          this.selectStatus = 2;
        }
      }

      // this.allSelected = selected.length;

      // console.log("selectStatus >>>>>>>> ", this.selectStatus);
      // console.log("this.selectAll >>>>>>>> ", this.selectAll);
      // console.log("this.newnumber >>>>>>>> ", this.newnumber);
      // console.log("this.selectedList >>>>>>>> ",this.$store.getters["productList/selected"]);
      // console.log("                                               ");
      // console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
      // return selected.length;
    },

    selectedNum() {
      var num = this.$store.getters["productList/selectedNumber"]();
      return num;
    },

    async addSelectedList(tr, event) {
      event.stopPropagation();

      var selectedOrNot = await this.$store.dispatch(
        "productList/addSelected",
        tr
      );

      var indextt = this.itemsInStock.findIndex(
        x => x.productItemId === tr.productItemId
      );

      if (indextt !== null && indextt !== undefined && indextt !== -1) {
        this.itemsInStock[indextt].selected = selectedOrNot;
      }

      var selected = [];
      Object.assign(selected, this.$store.getters["productList/selected"]);
      this.selectedList = this.checkSelectedStatus();

      //======================================================================

      // if (!this.itemsInStock[indextt].selected) {
      //   this.itemsInStock[indextt].selected = true;
      //   this.selectedList.push(this.itemsInStock[indextt]);
      //   // console.log("ADDED");
      // } else {
      //   if (this.selectedList) {
      //     this.itemsInStock[indextt].selected = false;
      //     var foundIndextt = this.selectedList.findIndex(
      //       x => x.orderNumber === this.itemsInStock[indextt].orderNumber
      //     );
      //     this.selectedList.splice(foundIndextt, 1);
      //     // console.log("REMOVED");
      //   }
      // }
      // this.itemsInStock[indextt].selected = !this.itemsInStock[indextt]
      //   .selected;

      //======================================================================
    },

    async startSearch() {
      // set page  = 1 every time that start to search
      this.currentx = 1;
      this.reload();
    },

    async addGroup(groupid, productid) {
      if (groupid === null || groupid === "" || groupid === undefined) {
        window.open("/backoffice/add-group/" + productid, "_blank");
      } else {
        window.open("/backoffice/add-group/" + groupid, "_blank");
      }
    },

    async submitBorrow(item, index) {
      this.$vs.loading({ type: "radius" });
      // console.log(
      //   "submitBorrow start >>>>>>>>> ",
      //   this.formatDate(item.pickupDate),
      //   "endDate >>>>>> ",
      //   this.formatDate(item.returnDate)
      // );

      var theItem = {
        productItemId: item.productItemId,
        approve: 0,
        startDate: this.formatDate(this.calendarBorrow.start),
        endDate: this.formatDate(this.calendarBorrow.end),
        // fromBranch: this.$store.state.auth.mybranchid,
        fromBranch: this.branchrad,
        toBranch: this.branchRadio[index],
        qty: this.reservedq[index],
        updateBy: this.currentUser
      };
      var res = null;
      try {
        res = await this.$http.post(
          this.$store.state.apiURL + "/api/borrow",
          theItem,
          this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("เกิดข้อผิดพลาดไม่สามารถส่งข้อมูลขอยืมชุดได้");
        }, 200);
      }
      if (res !== null) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("ส่งข้อมูลเพื่อขอยืมชุดแล้ว");
          this.borrowPanelActive = false;
        }, 200);
      }
    },
    selection1_result(ind, value) {
      this.blist[ind].branchId = value;
      this.branchRadio[ind] = value;
      this.borValid = 0;
  },

    openTabTo(orderid){
      window.open("/backoffice/order-detail/" + orderid, "_blank");
    },

    getOrderNumber(orderid) {


        var ord = this.orders.find(x => x.orderHeaderId === orderid);

        // console.log("ord >>>> ", ord);


        if (ord === null || ord === undefined) {
          return "ไม่ระบุ";
        } else {

          // console.log("ord.orderStatus >>>> ", ord.orderStatus);

          return ord.orderNumber;
        }
      },


    getOrderStatus(orderid) {


      var ord = this.orders.find(x => x.orderHeaderId === orderid);

      // console.log("ord >>>> ", ord);


      if (ord === null || ord === undefined) {
        return "ไม่ระบุ";
      } else {

        // console.log("ord.orderStatus >>>> ", ord.orderStatus);

        return ord.orderStatus;
      }
    },

    getCustomerName(orderid) {
      // console.log("getCustomerName >>>> ", orderid);

      var ord = this.orders.find(x => x.orderHeaderId === orderid);

      if (ord === null || ord === undefined) {
        return "";
      } else {
        return ord.customerName;
      }
    },
    // removeZero(list) {
    //   var tmp = [];
    //   var i = 0;
    //   if (this.$acl.check("staff")) {
    //     for (i = 0; i < list.length; i++) {
    //       if (this.mybranchstock(list[i].productItemId) > 0) {
    //         tmp.push(list[i]);
    //       }
    //     }
    //   } else {
    //     for (i = 0; i < list.length; i++) {
    //       tmp.push(list[i]);
    //     }
    //   }

    //   return tmp;
    // },
    seq(ind) {
      var seqnum = Number(ind);
      if (!isNaN(seqnum)) {
        return (seqnum + 1).toString() + " .";
      } else {
        return "0 .";
      }
    },
    async getBranch(id) {
      var response = null;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch/" + id,
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        response = null;
        this.noticeError(error);
      }

      return await response.data;
    },
    async loadProduct() {
      var response = null;
      // var tk = localStorage.getItem("token");
      // var theToken = JSON.parse(tk);
      //  var theUsername = JSON.parse(un);

      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem",
          this.$store.state.auth.apiHeader
        );
      } catch (error) {
        response = null;
        this.noticeError(error);
      }

      return await response.data;
    },


    // async loadStock(branchId) {
    //   var response;
    //   try {
    //     response = await this.$http.get(
    //       this.$store.state.apiURL + "/api/productitem/branch/" + branchId,
    //       this.$store.state.auth.apiHeader
    //     );
    //     if (response.status === 200) {
    //       this.branchStock = await response.data;
    //     }
    //   } catch (error) {
    //     this.noticeError(error);
    //     // console.log("error", error);
    //   }

    //   return await response.data;
    // },




    // qCheckIn: function(newDate, index) {
    //   this.checkqueueList[index].startDate = newDate;
    // },
    // qCheckOut: function(newDate, index) {
    //   this.checkqueueList[index].endDate = newDate;
    // },

    bCheckIn: function(newDate, index) {
      this.borValid = 0;
      //  console.log("this.borValid ", this.borValid);
      this.blist[index].pickupDate = newDate;
    },
    bCheckOut: function(newDate, index) {
      this.borValid = 0;
      //  console.log("this.borValid ", this.borValid);
      this.blist[index].returnDate = newDate;
    },

    // newCheckIn: function(newDate) {
    //   this.theCheckIn = newDate;
    // },
    // newCheckOut: function(newDate) {
    //   this.theCheckOut = newDate;
    // },

    mybranchstock(productId) {
      var st = this.thestock.find(
        x =>
          x.productItemId === productId &&
          x.branchId === this.$store.state.auth.mybranchid
      );
      if (st === null || st === undefined || st === "") {
        return 0;
      } else {
        return st.inStock;
      }
    },
    zoomimage(image) {
      this.zoomzoom = this.$store.state.imageStore + image;
      this.zoomPopupActive = true;
    },

    handleChangePage() {
      //  console.log("The user changed the page ");
    },

    checkBranch() {
      this.itemsInStock = [];
    },
    checkAll() {
      this.branchCheckbox = [];
      this.itemsInStock = [];
      //  console.log("this.branches >>> ", this.branches);
      //  console.log("this.allBranchCheckbox >>> ", this.allBranchCheckbox);

      if (this.allBranchCheckbox === true) {
        //  console.log("IF >>> ");
      } else {
        for (var i = 0; i < this.branches.length; i++) {
          this.branchCheckbox.push(this.branches[i].branchName);
        }
        this.resetlist();

        //  console.log("this.originalList >>> ", this.originalList);
      }
    },
    resetlist() {
      this.itemsInStock = [];
      for (var j = 0; j < this.originalList.length; j++) {
        this.itemsInStock.push(this.originalList[j]);
      }
    },
    getBarcode(code) {
      if (
        code === "" ||
        code === null ||
        code === undefined ||
        code === "01WSW001S"
      ) {
        return "ไม่ได้บันทึก";
      } else {
        return code;
      }
    },

    async reload() {
      this.btnActive = !this.btnActive;
      // this.$vs.loading({ type: "radius" });

      // LOAD ITEM
      try {
        // RELOAD STOCK
        var responseStock = await this.$http.get(
          this.$store.state.apiURL + "/api/stock",
          this.$store.state.auth.apiHeader
        );
        this.thestock = await responseStock.data;

        // RELOAD ITEMS

        var response = null;

        response = await this.$http.post(
          this.$store.state.apiURL + "/api/productitem/pagination",
          {
            T1: this.currentx,
            T2: this.pageitemSelected,
            T3: this.searchText
          },
          this.$store.state.auth.apiHeader
        );

        // console.log("RELOAD response >>> ", response);

        // response = await this.$http.get(
        //   this.$store.state.apiURL + "/api/productitem",

        //   this.$store.state.auth.apiHeader
        // );

        this.pageNumber = response.data.pageNumber;
      } catch (error) {
        response = null;
        this.$vs.loading.close();
      }

      if (response === null) {
        setTimeout(() => {
          // this.noticeError("ไม่สำเร็จ");

          this.$vs.loading.close();
        }, 200);
        this.btnActive = !this.btnActive;
      } else {
        //  console.log("ALREADY 200");
        this.itemsInStock = response.data.itemList;
        this.originalList = response.data.itemList;
        // if (this.$store.state.auth.permission === "staff") {
        //   this.itemsInStock = this.removeZero(response.data);
        //   this.originalList = this.removeZero(response.data);
        // }

        this.$vs.loading.close();

        // this.noticeSuccess("บันทึกเสร็จสิ้น");

        this.btnActive = !this.btnActive;
      }
      this.checkSelected();
    },

    async newload() {
      // this.$vs.loading({ type: "radius" });



      // LOAD ITEM
      try {
        //   // RELOAD STOCK

        // RELOAD STOCK
        var responseStock = await this.$http.get(
          this.$store.state.apiURL + "/api/stock",
          this.$store.state.auth.apiHeader
        );
        this.thestock = await responseStock.data;

        // RELOAD ITEMS

        var response = null;

        // console.log("this.searchParam >>>> ",this.searchParam);

        response = await this.$http.post(
          this.$store.state.apiURL + "/api/productitem/pagination",
          {
            T1: this.currentx,
            T2: this.pageitemSelected,
            T3: this.searchParam.searchText,

            T4: this.searchParam.category,
            T5: this.searchParam.texture,
            T6: this.searchParam.color,
            T7: this.searchParam.size,

            T8: this.searchParam.active,
            T9: this.searchParam.onWeb,
            T10: this.searchParam.hotItem,
            T11: this.searchParam.newArrival,
            T12: this.getCurrentBranch.branchId,
            //*** 13Nov2021 แสดงเฉพาะใน stock */
            showJustAvailable: this.showJustAvailable, //*** true -> แสดงเฉพาะที่มีใน stock สาขานั้นๆ false -> จะมีหรือไม่แสดงหมด
            //*** 17Apr 2022 sorting with {createDateTime, beststat, price}
            T15 : this.sorting1
          },
          this.$store.state.auth.apiHeader
        );

        this.pageNumber = response.data.pageNumber;
      } catch (error) {
        response = null;
        this.$vs.loading.close();
      }

      if (response === null) {
        setTimeout(() => {
          // this.noticeError("ไม่สำเร็จ");

          this.$vs.loading.close();
        }, 200);
        this.btnActive = false;
      } else {
        //  console.log("ALREADY 200");

         console.log("NEWLOAD() this.itemsInStock ",this.itemsInStock);
        this.itemsInStock = response.data.itemList;
        this.originalList = response.data.itemList;
        // if (this.$store.state.auth.permission === "staff") {
        //   this.itemsInStock = this.removeZero(response.data);
        //   this.originalList = this.removeZero(response.data);
        // }

        this.$vs.loading.close();

        // this.noticeSuccess("บันทึกเสร็จสิ้น");

      }

      this.checkSelected();
    },

    async removeItem(event, ind, item) {
      event.stopPropagation();
      this.removePopupActive = true;
      this.removeIndex = ind;
      this.removeCode = item.code;
      this.removeId = item.productItemId;
    },
    async remove() {
      var resp = await this.$http.delete(
        this.$store.state.apiURL + "/api/productitem/" + this.removeId,
        this.$store.state.auth.apiHeader
      );
      // var resp = await productService.remove(this.$vs.notice, this.removeId);
      if (resp.status === 200) {
        this.noticeSuccess("ลบ " + this.removeCode + " สำเร็จ");
        this.itemsInStock.splice(this.removeIndex, 1);
        this.closeRemovePanel();
      } else {
        this.noticeSuccess("มีข้อผิดพลาด");
      }
    },
    closeRemovePanel() {
      this.removePopupActive = false;
      this.removeIndex = -1;
      this.removeCode = "";
      this.removeId = "";
    },
    getFullpath(mainImage) {
      if (
        mainImage === undefined ||
        mainImage === null ||
        mainImage === "" ||
        mainImage === this.$store.state.imageStore
      ) {
        return this.$store.state.defaultImage;
      } else {
        return this.$store.state.imageStore + mainImage;
      }
    },
    async changeState(ind) {
      // console.log(
      //   "ind >>> ",
      //   ind,
      //   " this.itemsInStock[ind].state >>> ",
      //   this.itemsInStock[ind].state
      // );

      await this.put(
        "/api/productitem",
        this.itemsInStock[ind],
        this.itemsInStock[ind].productItemId
      );

      // productService.update(
      //   this.itemsInStock[ind],
      //   this.itemsInStock[ind].productItemId
      // );
    },
    getCategoryName(codename) {
      var c = this.categories.find(x => x.code === codename);

      var categoryName = "";
      if (c === null || c === undefined) {
        categoryName = "ไม่มี";
      } else {
        categoryName = c.description;
      }

      return categoryName;
    },
    getColorName(codename) {
      var c = this.colors.find(x => x.code === codename);

      var colorName = "";
      if (c === null || c === undefined) {
        colorName = "ไม่มี";
      } else {
        colorName = c.description;
      }

      return colorName;
    },
    getSizeName(codename) {
      var c = this.sizes.find(x => x.code === codename);

      var colorName = "";
      if (c === null || c === undefined) {
        colorName = "ไม่มี";
      } else {
        colorName = c.description;
      }

      return colorName;
    },
    async newItem() {
      // console.log("NEW ITEM");
      this.$vs.loading({ type: "radius" });
      try {
        //   var response = await this.$http.post(
        //     this.$store.state.apiURL + "/api/productgroup",
        var item = {
          name: "New Item",
          code: "",
          category: "",
          texture: "",
          size: "",
          yearth: 1,
          gender: "",
          itemAmount: 1,
          rentalPrice: 0,
          bail: 0,
          itemStatus: "เปิด",
          webCategory: "",
          webHotItem: "ไม่ใช่",
          webItem: "n",
          itemCode3Digit: "000",
          color: "",
          newRecord: true,
          temporary: false,
          state: true,
          updateBy: this.currentUser,
          createBy: this.currentUser
        };
        var res = await this.$store.dispatch("productGroup/create", item);
        // console.log("res >>>>>>>>>>>> ", res);

        if (res) {
          await this.$router.push("/backoffice/additem/" + res.productGroupId);
        }
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.loading.close();
        this.noticeError(error);
        return;
      }
    },
    editItem(event, productItemId) {
      event.stopPropagation();
      // window.open("/backoffice/updateitem/" + productItemId, "_blank");
      this.$router.push("/backoffice/updateitem/" + productItemId);
    },
    onDateSelected: function(daterange) {
      this.selectedDate = daterange;
    },
    // FOR PRINT CARD
    arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = [].slice.call(new Uint8Array(buffer));

      bytes.forEach(b => (binary += String.fromCharCode(b)));

      return window.btoa(binary);
    },

    async convertFileToDataURLviaFileReader(url) {
      let resp = await fetch(url);

      //  console.log("convertFileToDataURLviaFileReader >>> ", resp);

      var base64Flag = await "data:image/png;base64,";
      var imageStr = this.arrayBufferToBase64(await resp.arrayBuffer());
      var imageurl = base64Flag + imageStr;
      return imageurl;
    },

    // >>>>>>> CHECK QUEUE >>>>>>>>>
    async checkQueue(item, ind) {
      // เช็ค productItemId / this.startDate >=  /  =< this.endDate
      //  ต้องเทียบเป็นวัน
      // เอาจำนวนที่ยืม + จำนวนที่ยืมไปแล้ว` <= จำนวนที่มีใน Branch
      // console.log("ind :: ", ind, " ITEM CODE >>>> ", item.code);
      item.startDate = this.formatDate(this.calendarCheckq.start);
      item.endDate = this.formatDate(this.calendarCheckq.end);
      if (
        item.startDate === null ||
        item.startDate === undefined ||
        item.endDate === null ||
        item.endDate === undefined
      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบถ้วน",
          "ยังไม่ได้กำหนดวันรับชุด หรือวันคืนชุด"
        );

        return;
      }

      if (this.formatDate(item.startDate) > this.formatDate(item.endDate)) {
        this.noticeWarning(
          "ข้อมูลไม่ถูกต้อง",
          "การกำหนดวันรับชุดต้องอยู่ก่อนวันคืนชุด !!!"
        );

        return;
      }

      this.$set(this.checkqq, ind, "ไม่ติด");

      // หาว่ามีกี่วัน
      var diffDate = this.date_diff_indays(item.startDate, item.endDate);
      diffDate = diffDate + this.washingDate;
      // Challenge by set notAvailable to FALSE
      // item.notAvailable = false;
      // this.checkqueueList[ind].message = "ไม่ติด";

      // console.log(">>>>>>>>>>>>> CHECK QUEUE >>>>>>>>>>>>>");

      // console.log("item.code >>>>>  ", item.code, " ind >>>>>  ", ind);

      // BEGIN [Edit] [13May2024]

      // var bst = this.thestock.filter(
      //   s =>
      //     s.productItemId === item.productItemId &&
      //     s.branchId === this.thisBranch.branchId
      // );

      var bst = item.branchStock


      // END [Edit] [13May2024]


      // *** BEGIN : 2021-03-14 : แอดจำนวนในสต็อค ที่ไม่มี

      // console.log("bst.productItemId >>> ",bst[0].productItemId, "  bst.branchId >>> ",bst[0].branchId);
      // console.log("bst.productItemId >>> ",bst);
      if(bst.length > 0){
        item["branchStock"] = bst;
        // ถ้าของมีจำนวน 0
        // if (item["branchStock"]["inStock"] === 0) {
        if (bst[0]["inStock"] === 0) {

          this.$set(this.checkqq, ind, "ไม่มีของ");
          //  console.log("item.code >>>>>  ", item.code, " ind >>>>>  ", ind);
           console.log("ไม่มีของ 1 >>>>  ");

           return;
        }
        // else{
        //   console.log("ติด ติดติดต >>>>  ");
        // }
      }else{
        this.$set(this.checkqq, ind, "ไม่มีของ");
        var newbst = [{
            branchId: this.thisBranch.branchId,
            code: null,
            inStock: 0,
            productItemId: item.productItemId,
            shopInfoId: item.shopInfoId,
            stockId: "xxx",

        }];
        item["branchStock"] = newbst;

         console.log("ไม่มีของ 2 >>>>  ");

          return;
      }

      // *** END : 2021-03-14 : แอดจำนวนในสต็อค

      // item ที่ได้คือ item ใน branch stock
      // เอาค่า item.amountInBranch
      // ต้องเทียบทีละวัน

      //  console.log(" diffDate >>> ", diffDate);

      for (var da = 0; da < diffDate + 1; da++) {
        // เริ่มเทียบวันแรก ค่อยๆ เทียบไปทีละวันจนวันสุดท้าย
        // var theDate = this.addDay(new Date(this.startDate), da);
        var theDate = this.addDay(new Date(item.startDate), da);
        theDate = this.formatDate(theDate);
        // console.log("theDate >>> ", theDate);

        // console.log("[", da, "] : ITEM CHECK QUEUE >>>> ");

        // dlist คือ orderDetail List ทั้งหมด
        // state = true คือ queue ที่โดน LOCK อยู่ !!!
        var condition = this.dlist.filter(
          f =>
            f.productItemId === item.productItemId &&
            this.formatDate(f.startDate) <= theDate &&
            theDate <= this.formatDate(f.wnfReceivingDate) &&
            f.state === true &&
            // เอาแต่เฉพาะสินค้าของ Branch นี้
            f.branchId === this.thisBranch.branchId
        );

        // console.log(" theDate >>> ", theDate);
        // console.log(" condition >>> ", condition);

        var conditionSum = 0;
        for (var j = 0; j < condition.length; j++) {
          conditionSum = conditionSum + condition[j].reservedQuantity;
          // console.log(
          //   "[da] [",
          //   da,
          //   "] [",
          //   j,
          //   "] : conditionSum >>>>>>> ",
          //   condition[j].reservedQuantity
          // );
        }

        //  console.log("conditionSum >>>>>>> ", conditionSum);

        // ค่า reservedQuantity ทั้งหมด + ตัวมันเอง ต้องไม่มากกว่า ของที่มีใน stock
        var allReservedQuantity = 0;

        allReservedQuantity =
          Number(this.reservedq[ind]) + Number(conditionSum);

        // var allReservedQuantity = conditionSum;

        if (allReservedQuantity > item.branchStock[0].inStock) {
          // * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          // * >>>>>>>>>>>   RESULT   >>>>>>>>>>>>>>>>
          // * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          // this.checkqueueList[ind].notAvailable = true;
          // item.notAvailable = true;
          // this.checkqueueList[ind].message = "ติด";
          this.$set(this.checkqq, ind, "ติด");

          break;
          // ถ้าไม่ว่างก็ หยุดได้เลย
        }
      }

      //  console.log("item.code >>>>>  ", item.code, " ind >>>>>  ", ind);

      //  console.log("this.checkqq >>>>>>> ", this.checkqq);
      //  console.log("IND  >>>>>>> ", ind);
      //  console.log("this.checkqueueList  >>>>>>> ", this.checkqueueList);
      // this.checkqueueList[ind] = item;
    },
    // >>>>>>> CHECK QUEUE >>>>>>>>>

     checkqueueListPanel() {
      // event.stopPropagation();
      console.log("checkqueueListPanel");
      // items = this.pdlist.legnth;

      // await this.$store.dispatch("washingList/getOrderDetail");

      if(this.pdlist.length <= 0)
      {
        this.$swal({
          icon: 'warning',
          title: 'กรุณาเลือกสินค้าที่ต้องการเช็คคิวก่อน',
        });

        return ;
      }

      if(this.pdlist.length > 20)
      {
        this.$swal({
          icon: 'warning',
          title: 'เช็คคิวสินค้าได้ครั้งละไม่เกิน 20 ชิ้น',
        });

        return ;
      }

      this.checkqueuePanelActive = true;


      this.checkqueueList = [];
      this.blist = [];
      this.checkqq = [];
      this.reservedq = [];

      // this.calendarCheckq = this.calendarData;
      //  console.log(" @@@@ this.pdlist >>> ", this.pdlist);

      for (var i = 0; i < this.pdlist.length; i++) {
        var anItem = {};
        anItem = this.pdlist[i];
        anItem.startDate = this.formatDate(this.calendarCheckq.start);
        anItem.endDate = this.formatDate(this.calendarCheckq.end);



        // anItem.message = "ไม่ติด";
        anItem.reserved = 1;
        this.reservedq[i] = 1;
        this.checkqq[i] = "";


        // *** BEGIN: [Edit] [13May2024]

        // * >>>>>>>>>> หาจำนวนในสต็อค
        // var bst = this.thestock.filter(
        //   s =>
        //     s.productItemId === anItem.productItemId &&
        //     s.branchId === this.thisBranch.branchId
        // );

        // anItem["branchStock"] = bst;
        // * >>>>>>>>>> หาจำนวนในสต็อค

        //*** เอาออกเพราะผลคาดเคลื่อน
        this.checkQueue(anItem, i);


        // var detail = this.dlist.filter(
        //   dd =>
        //     dd.state === true &&
        //     dd.branchId === this.thisBranch.branchId &&
        //     dd.productItemId === this.pdlist[i].productItemId
        // );

        // anItem["dlist"] = detail.sort( (a,b) =>  new Date(b.startDate) - new Date(a.startDate) )
        
        // *** END: [Edit] [13May2024]
       
        this.checkqueueList.push(anItem);
      }

      // this.checkqueueList.sort( (a,b) =>  new Date(a.startDate) - new Date(b.startDate))

      // this.checkqueueList = this.ascendingDateSortShare(this.checkqueueList, "startDate")
      // this.checkqueueList.sort( (a,b) =>  new Date(a.pickupDate) - new Date(b.pickupDate))
      //  console.log("this.checkqueueList >>> ", this.checkqueueList);
    },
     checkqueuePanel(event, anItem) {
      event.stopPropagation();

      

      // await this.$store.dispatch("washingList/getOrderDetail");

      console.log("checkqueuePanel ");
      this.checkqueuePanelActive = true;
      this.checkqueueList = [];
      this.checkqq = [];
      this.reservedq = [];
      // anItem.message = "ไม่ติด";
      anItem.reserved = 1;

      anItem.startDate = this.formatDate(this.calendarCheckq.start);
      anItem.endDate = this.formatDate(this.calendarCheckq.end);

      this.checkqq[0] = "";
      this.reservedq[0] = 1;

      // *** BEGIN: [Edit] [13May2024]

      // * >>>>>>>>>> หาจำนวนในสต็อค
      // var bst = this.thestock.filter(
      //   s =>
      //     s.productItemId === anItem.productItemId &&
      //     s.branchId === this.thisBranch.branchId
      // );

      // anItem["branchStock"] = bst;
      // * >>>>>>>>>> หาจำนวนในสต็อค

      //*** เอาออกเพราะผลคาดเคลื่อน
      // this.checkQueue(anItem, 0);

      this.checkqueueList.push(anItem);

      // var detail = this.dlist.filter(
      //   dd =>
      //     dd.state === true &&
      //     dd.branchId === this.thisBranch.branchId &&
      //     dd.productItemId === anItem.productItemId
      // );

      // console.log("checkqueuePanel detail  >>> ", detail);
      //  anItem["dlist"] = this.ascendingSortShare(detail, "startDate");
      // anItem["dlist"] = detail.sort( (a,b) =>  new Date(b.startDate) - new Date(a.startDate) )

      // *** END: [Edit] [13May2024]

      console.log("CHECK QUEUE PANEL", this.checkqueueList);
    },

    closeCheckqueuePanel() {
      this.checkqueuePanelActive = false;
      this.checkqueueList = [];
      this.checkqq = [];
      this.reservedq = [];
    },


    async orderHistoryPanel(event, anItem, ind) {
      event.stopPropagation();
      this.orderHistoryList = []
      this.orderHistoryPanelActive = true;

      this.historyItem = anItem;

      // this.orderHistoryList.push(anItem)
      let productId = anItem.productItemId

      var resp = null;
      try {
        resp = await this.$http.get(
        this.$store.state.apiURL + "/api/orderheader/get-order-by-product/" + productId,
        this.$store.state.auth.apiHeader
      );
      // console.log("orderHistoryPanel >>> ", resp.data);

      this.orderHistoryList = await resp.data
      
      // resp.data.forEach( 
      //   (dat ,  indx) => {
      //   this.$set(this.orderHistoryList , indx ,dat );
      //   // (dat) => {
      //   // this.orderHistoryList.push(dat)
      // })
      

    } catch (error) {
      resp = null;
      this.shapeFormat.noticeError(error);
      // appropriately handle the error
    }

      console.log(":::  INDEX ::: " ,ind, ":::  DATA ::: " , this.orderHistoryList);

      
    },

    closeOrderHistoryPanel(){
      this.orderHistoryPanelActive = false;
      this.historyItem = null;
      this.orderHistoryList = [];

    },

    

    async chkq(item, ind) {
      // console.log(
      //   "chkq item.pickupDate >>> ",
      //   item.pickupDate,
      //   "  item.returnDate  >>> ",
      //   item.returnDate
      // );

      if (
        this.calendarBorrow === null ||
        this.calendarBorrow === undefined ||
        Object.entries(this.calendarBorrow).length === 0
      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบถ้วน",
          "คุณยังไม่ได้กำหนดวันรับชุดและวันคืนชุด"
        );
        return;
      }
      if (
        isNaN(Number(this.reservedq[ind])) ||
        this.numberFormat(this.reservedq[ind]) <= 0
      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบถ้วน",
          "คุณยังไม่ได้จำนวนสินค้าที่ต้องการขอยืม"
        );
        return;
      }

      var itemList = [];
      var theItem = {
        productItemId: item.productItemId,
        // *** แก้ไข 2020-11-14 PHASE1
        code: item.code,
        // pickupDate: this.blist[ind].pickupDate,
        // returnDate: this.blist[ind].returnDate,
        pickupDate: this.formatDate(this.calendarBorrow.start),
        returnDate: this.formatDate(this.calendarBorrow.end),
        branchId: this.branchRadio[ind],
        qty: this.numberFormat(this.reservedq[ind])
      };
      itemList.push(theItem);

      //  console.log("itemList >>> ", itemList);

      this.checkRes = await this.checkQueueShare(itemList);

      if ((await this.checkRes) === null) {
        this.noticeWarning(
          "ข้อมูลไม่ครบถ้วน",
          "กรุณาตรวจสอบข้อมูลอีกครั้งและใส่ให้ครบถ้วน"
        );

        return;
      } else {
        if (this.checkRes[ind].result === 1) {
          this.borValid = 1;
        }
      }

      // if (this.checkRes[ind].result === 1) {
      //   this.borValid = 1;
      // }

      //  console.log("this.checkRes >>> ", this.checkRes);
    },

    //   closeCheckqueuePanel() {
    //   this.checkqueuePanelActive = false;
    //   this.checkqueueList = [];
    //   this.checkqq = [];
    //   this.reservedq = [];
    // },

    getRedirectLink() {
      return "/backoffice/create-item/" + this.panelId;
    },
    getRedirect(id) {
      return "/backoffice/create-item/" + id;
    },
    amountOfAssignItem(branchId) {
      var ai = this.assignItems.find(x => x.branchId === branchId);
      // console.log("this.assignItems >>> ", this.assignItems);
      // console.log("INPUT branchId >>> ", branchId);
      // console.log("ai >>> ", ai);

      if (ai != null && ai != undefined) {
        return ai.inStock;
      } else {
        return 0;
      }
    },
    assignItemPanel: function(event, ind, item) {
      // event.stopPropagation();

      this.$router.push("/backoffice/assign-to-branch/" + item.productItemId);
      // this.panelId = item.productItemId;
      // this.assignItemPanelActive = true;
      // this.assignItems = [];

      // this.currentAmountInstock = item.inStock;

      // var branchStock = this.thestock.filter(
      //   x => x.productItemId === item.productItemId
      // );

      // //  console.log("branchStock >>> ", branchStock);

      // // this.assignItems = this.itemsInStock[ind].branchAmount;
      // this.assignItems = branchStock;
      // var assignedItemsSum = 0;
      // for (var i = 0; i < branchStock.length; i++) {
      //   assignedItemsSum = assignedItemsSum + branchStock[i].inStock;
      // }
      // this.remainingItems = this.currentAmountInstock - assignedItemsSum;
      // // console.log("Item In Stock  >>> ", this.itemsInStock);

      // this.indexToAssign = ind;
    },

    resetpage() {
      this.lastPage = 0;
    },

    selectAllOrders: function(event) {
      event.stopPropagation();

      var cc = document.getElementsByClassName("is-current");
      var page_th = cc[0].getElementsByTagName("span")[0].innerText;
      var serow = document.querySelectorAll("input[data-id]");

      var codelist = [];

      for (var n = 0; n < serow.length; n++) {
        codelist.push(serow[n].getAttribute("data-id"));
      }

      var page_th_num = Number(page_th);
      if (isNaN(page_th_num)) {
        page_th_num = 1;
      }
      // console.log(" trow >>>>>>>>>>>>>>>>>>>>>>>>> ", trow[0].outerHTML);

      if (this.lastPage === 0) {
        //*** เข้ามาครั้งแรก
        this.lastPage = page_th_num;
      } else {
        //*** เปลี่ยนหน้าใหม่
        if (this.lastPage !== page_th_num) {
          this.selectStatus = 0;
          this.selectAll === false;
          this.lastPage = page_th_num;
          this.selectedList = [];
          for (ind = 0; ind < this.itemsInStock.length; ind++) {
            this.itemsInStock[ind].selected = false;
          }
        }
      }

      // ************************************************************************
      var ind = 0;

      // ************************************************************************
      //  คำนวณจำนวนที่ต้องเลือก
      // ************************************************************************

      // var first = (page_th_num - 1) * this.pageitemSelected + 1;
      // var last =
      //   (page_th_num - 1) * this.pageitemSelected + this.pageitemSelected;

      // if (last > this.itemsInStock.length) {
      //   last = this.itemsInStock.length;
      // }

      // ใช้แค่ code ที่ต้องการเลือกมา

      // init selectedList

      // this.selectedList = [];

      // for (var k = 0; k < codelist.length; k++) {
      //   var theind = this.itemsInStock.findIndex(xx => xx.code === codelist[k]);

      //   console.log("[", k, "] theind >>>>> ", theind);

      //   if (theind >= 0) {
      //     this.itemsInStock[theind].selected = true;
      //     this.selectedList.push(this.itemsInStock[theind]);
      //   }
      // }

      // ************************************************************************

      var k = 0;
      var theind = -1;
      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0) {
        //  console.log("expected >>> 0");
        this.selectedList = [];

        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = true;
        //   this.selectedList.push(this.itemsInStock[ind]);
        // }

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          //  console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = true;
            this.selectedList.push(this.itemsInStock[theind]);
          }
        }

        this.selectStatus = 1;
        //  console.log("go out with >>> 1");
      } else if (this.selectStatus === 2) {
        //  console.log("expected >>> 2");
        // this.selectedList = [];
        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = true;
        //   this.selectedList.push(this.itemsInStock[ind]);
        // }

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          //  console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = true;
            this.selectedList.push(this.itemsInStock[theind]);
          }
        }
        this.selectStatus = 1;
        //  console.log("go out with >>> 1");
      } else if (this.selectStatus === 1) {
        //  console.log("expected >>> 1");
        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = false;
        // }
        // this.selectedList = [];

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          //  console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = false;
          }
        }

        this.selectedList = [];

        this.selectStatus = 0;
        //  console.log("go out with >>> 0");
      }
      this.fromSelectAll = true;
      // ************************************************************************
      // **  BEGIN : OLD CODE
      // ************************************************************************

      // if (this.selectAll === false && this.selectStatus === 1) {
      //   this.selectAll = true;
      // }
      // if (this.selectStatus === 0 || this.selectStatus === 2) {
      //   // console.log("expected >>> 0 or 2");
      //   this.selectedList = [];
      //   for (ind = 0; ind < this.itemsInStock.length; ind++) {
      //     this.itemsInStock[ind].selected = true;
      //     this.selectedList.push(this.itemsInStock[ind]);
      //   }
      //   this.selectStatus = 1;
      // } else if (this.selectStatus === 1) {
      //   // console.log("expected >>> 1");
      //   for (ind = 0; ind < this.itemsInStock.length; ind++) {
      //     this.itemsInStock[ind].selected = false;
      //   }
      //   this.selectedList = [];
      //   this.selectStatus = 0;
      // }
      // this.fromSelectAll = true;

      // ************************************************************************
      // **  END : OLD CODE
      // ************************************************************************
    }

    // formatPrice(value) {
    // let val = (value / 1).toFixed(2).replace(",", ".");
    // console.log(value);
    // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
  },

  components: {
    CatalogPanel,
    WashPanel,
    Multiselect,
    BEmbed,
    Prism,
    Datepicker,
    // VueRangedatePicker,
    // VueHotelDatepicker,
    "v-select": vSelect,
    // HotelDatePicker,
    Selection1,UpdateStockSidebar,FilterStockSidebar
  },
  watch: {

    async branchDisplaySelected(value){
        if(value === "แสดงเฉพาะสาขา"){
            this.showJustAvailable = true;
            await this.newload();
            console.log("แสดงเฉพาะสาขา");


        }else if(value === "สินค้าทั้งหมด" ){
          this.showJustAvailable = false;
          await this.newload();
          console.log("สินค้าทั้งหมด");
        }
    },

    currentx: function() {
      this.newload();
    },
    pageitemSelected: function() {
      this.newload();
    },
    calendarData: {
      handler(value) {
        this.theCheckIn = this.formatDate(value.start);
        this.theCheckOut = this.formatDate(value.end);

        // this.qCheckIn = this.formatDate(value.dateRange.start.date);
        // this.qCheckOut = this.formatDate(value.dateRange.end.date);

        // console.log(
        //   "WATCH : this.qCheckIn >>> ",
        //   this.qCheckIn,
        //   "  this.qCheckIn >>> ",
        //   this.qCheckOut
        // );

        this.calendarCheckq = value;
        // this.calendarCheckq.dateRange.start.date = this.formatDate(value.dateRange.start.date);
        // this.calendarCheckq.dateRange.end.date = this.formatDate(value.dateRange.end.date);
        //  console.log("WATCH :  this.calendarCheckq >>> ", this.calendarCheckq);
      },
      deep: true
    },
    calendarCheckq: {
      handler(value) {
        this.qCheckIn = this.formatDate(value.start);
        this.qCheckOut = this.formatDate(value.end);
      },
      deep: true
    },
    async branchrad(value) {
      // FIND THIS BRANCH
      this.thisBranch = await this.getBranch(value);

      // FIND OTHER BRANCHES
      this.otherBranches = await this.branches.filter(
        x => x.state === true && x.branchId !== this.thisBranch.branchId
      );
      this.firstOtherBranch = this.branches.find(
        x => x.state === true && x.branchId !== this.thisBranch.branchId
      );

      // console.log("BRANCH RAD >>> ",value);
      // console.log("BRANCH RAD >>> ", this.thisBranch);
    },
    reservedq: {
      handler() {
        this.borValid = 0;
      },
      deep: true
    },

    async getCurrentBranch(){
       this.$vs.loading();
        await this.newload();
        this.$vs.loading.close();
      this.branchrad = this.getCurrentBranch.branchId;

    },

    branchCheckbox: {
      handler() {
        if (this.branchCheckbox.length === this.branches.length) {
          this.resetlist();
          this.allBranchCheckbox = true;
        } else {
          if (this.branchCheckbox.length >= 1) {
            for (var i = 0; i < this.originalList.length; i++) {
              var saved = false;
              for (var j = 0; j < this.branchCheckbox.length; j++) {
                if (saved === true) continue;
                var branchname = this.branchCheckbox[j];
                // จะเอา branch ID
                var ab = this.branches.find(x => x.branchName === branchname);
                // หา ID ของ ใน stock
                if (ab != null && ab != undefined) {
                  var stock_branch = this.thestock.find(
                    s =>
                      s.branchId === ab.branchId &&
                      s.productItemId === this.originalList[i].productItemId &&
                      s.inStock > 0
                  );
                  // ได้ id ของ branch เอามาเทียบว่ามี ใน
                  if (stock_branch != null && stock_branch != undefined) {
                    this.itemsInStock.push(this.originalList[i]);
                    saved = true;
                  }
                }
              }
            }
          }
        }
      },
      deep: true
    },

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    assignItems: {
      handler() {
        // var assignedItemsSum = 0;
        // for (var i = 0; i < this.assignItems.length; i++) {
        //   assignedItemsSum = assignedItemsSum + this.assignItems[i].amount;
        // }
        // this.remainingItems = this.currentAmountInstock - assignedItemsSum;
        // console.log("WHEN CHANGE : Item In Stock  >>> ", this.itemsInStock);
      },
      deep: true
    },

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    dlist: {
      handler() {
         console.log("@@@@@@@@@@@@@@@@@@@ dlist CHANGE @@@@@@@@@@@@@@@@@@@")
      },
      deep: true
    },
    orders: {
      handler() {
        console.log("%%%%%%%%%%%%%%%% orders CHANGE  %%%%%%%%%%%%%%%%%")
      },
      deep: true
    }

  }
};
</script>

<style>

.order-btn-active{
  border: 1px solid black; display:inline-block; padding: 5px !important; cursor:pointer;
  background-color: #626262 !important; color: white !important;
}

.order-btn{
  border: 1px solid black; display:inline-block; padding: 5px !important; cursor:pointer;
}

.multiselect__content-wrapper .multiselect-leave-active{
  z-index: 99999 !important;
}

.vs__search{
  display: none !important;
}
.clear-btn:hover{
   text-decoration: underline;
}
/* .vs-pagination--nav {
  display: none;
} */

.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: mitr;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.checkqueuelist {
  margin-top: 0;
}
.input-date {
  width: 100% !important;
}

.dropdown-toggle .clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none;
}
.selected-tag {
  font-size: 12px;
}

/* VUE HOTEL DATEPICKUP */
.previous-arrow .offset-2 .disabled {
  display: block !important;
}

/* .calendar {
  position: relative !important;
} */

/************* VUE HOTEL DATEPICKUP **************/

.datepicker__input {
  font-size: 12px !important;
  line-height: 4 !important;
  padding: 4px 25px 2px !important;
}

.con-vs-popup .vs-popup {
  width: 750px;
}

.con-vs-popup .vs-popup .datepicker {
  left: -285px;
  padding: 0 10px;
  z-index: 999 !important;
}

.datepicker.datepicker--closed {
  display: none;
}
.con-vs-popup .vs-popup .datepicker__inner {
  padding: 0;
  float: left;
  padding-bottom: 5px;
}

.con-vs-popup .vs-popup .datepicker__month-day {
  visibility: visible;
  text-align: center;
  margin: 0;
  border: 0;
  height: 25px;
  padding-top: 5px !important;
  vertical-align: middle;
}

.con-vs-popup .vs-popup .datepicker__week-row {
  border-bottom: 5px solid #fff;
  height: 20px;
}

.zoomimage {
  width: 400px;
}
.datepicker__tooltip {
  display: none !important;
}

.demo-alignment {
  vertical-align: middle;
}

li {
  margin: 0 !important;
}

@media only screen and (max-width: 578px) {
  #main-background {
    width: 100% !important;
    padding: 0 !important;
  }
  #controller-panel {
    width: 91% !important;
  }
}
</style>
