
<template lang='pug'>

    //*** POP UP : SEND to WASH
    div
      vs-popup(classContent='popup-example' title='ส่งชุดดำเนินการ' :active.sync='switchPanel')
        //*** หัวข้อ
        vs-row.row-form(vs-w='12' style="background-color:rgb(248, 248, 248)" )
          vs-col.form-label.py-4.px-6(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
            h4(style="font-size:1.7rem;") ส่งชุดดำเนินการ

        //*** เส้นแบ่ง
        vs-divider.mb-0(v-if="sendingAction===1"  )

        .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%; padding: 20px 0px;')

          //*** ช่วงเวลาส่งซัก
          vs-row.row-form(vs-w='12' style='margin-top:20px;' , v-if="sendingAction===1")
            vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2' vs-offset='2') ช่วงเวลา
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 9999 !important;')
              //*** ข้อมูลส่งชุดซัก/ซ่อม
              v-date-picker(style='width:80%;' :locale="{ id: 'th', firstDayOfWeek: 2, masks: {  L: 'DD/MM/YYYY' } }" mode='range' :min-date='new Date()' v-model='washingDate')
          //*** ร้านซัก/ซ่อม
          vs-row(vs-w='12' style='margin-top:20px;' , v-if="sendingAction===1")
            vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2' vs-offset='2' style='z-index : 10 !important;') ผู้ติดต่อ
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 9995 !important;')
              multiselect(style='width:80%; z-index : 9999 !important;' v-model='washingShop' :options='washingShopList' label="wnfShopName" track-by="wnfShopId", placeholder="พิมพ์เพื่อค้นหา",)
                span(slot='noResult') ไม่พบข้อมูล
          //*** หมายเหตุ
          vs-row.row-form(vs-w='12' style='margin-top:20px;' , v-if="sendingAction===1" )
            vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='2' vs-sm='2' vs-xs='2' vs-offset='2' style='z-index : 10 !important;') หมายเหตุ
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 10 !important;')
              .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%; ')
                vs-row.row-form(vs-w='12')
                  vs-textarea(style='display:block; width:80%;' v-model='infoNote')
                vs-row.row-form(vs-w='12')
                  div(style='font-size:11px;  color: #4a90e2;') (เหลือความยาวตัวอักรษร {{remainingText(500,infoNote)}} )


          //*** สาขา
          vs-row.row-form(vs-w='12')
            vs-col.form-label.py-6.px-6(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
              h4(style="font-size:1.2rem;") {{getCurrentBranch.branchName}}



          //*** ROW OF SENDING TO WASH
          //*** POPUP
          vs-row(style='display: flex;width: 100%;padding: 20px 0px;margin-bottom: 30px;border-bottom: 1px solid #e4e4e4;' vs-w='12' v-for="(sitem, wash_index) in selectedItem" :key="wash_index")
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='3' vs-sm='3' vs-xs='3' vs-offset='1')
              div(style='vertical-align: text-top;')
                img.responsive.rounded(:src='getFullpath(sitem.mainImage)' alt='user-upload' style='width:100px;')
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='5' vs-sm='5' vs-xs='5')
              .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%;')

                //*** ข้อมูลสินค้า
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:1.2rem;color: #4a90e2;") {{sitem.name}}
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") ประเภท : {{getCategoryName(sitem.category)}}

                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") สี : {{getColorName(sitem.color)}}

                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") ไซส์ : {{getSizeName(sitem.size)}}

                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") รหัส : {{sitem.code}}

            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='2' vs-sm='2' vs-xs='2')
              .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%;')
                //*** จำนวน
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='center' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:1.2rem;") จำนวน
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='center' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    vs-input-number(style="width:50px; color: red;", min="0", :max="minimumRemaining[wash_index].remaining", v-model="qty[wash_index]")
                    //- span(style="font-size:1.2rem;") {{sitem.reservedQuantity}}

          //*** เส้นแบ่ง
          vs-divider.mb-0


          //*** ปุ่ม
          vs-row(vs-w='12' vs-type='flex' vs-justify='flex-end' style=' padding : 20px 20px; background-color: #F8F8F8; ')
            vs-button.btn-danger( v-if="sendingAction===3" style='background-color:red !important; width:150px; margin-right: 20px;', @click="sendingToWash()" , :disabled='!btnActive' ) ไม่ส่งซัก
            vs-button.btn-success( v-if="sendingAction===1" style='width:150px; margin-right: 20px;', @click="sendingToWash()" , :disabled='!btnActive' ) บันทึก
            vs-button.btn-success( v-if="sendingAction===2" style='width:150px; margin-right: 20px;', @click="sendingToWash()" , :disabled='!btnActive' ) กดเพื่อรับของ

            vs-button(style='background-color:#4A4A4A !important; width:120px;' @click='closeSendItem()' type='filled') ยกเลิก





</template>

<script>

import { BEmbed } from 'bootstrap-vue'
import Multiselect from "vue-multiselect";
import Prism from "vue-prism-component";
import vSelect from "vue-select";
// import HotelDatePicker from "vue-hotel-datepicker";
import Datepicker from "vuejs-datepicker";
import shapeFormat from "../../mixins/shapeFormat.js";
// import ProductService from "./Services/ProductService";
import Selection1 from "./Components/Selection1";
// import UpdateStockSidebar from './Components/UpdateStockSidebar.vue'
import FilterCleanFixSidebar from './Components/FilterCleanFixSidebar.vue'

// const productService = new ProductService();


export default {
  mixins: [shapeFormat],
  data() {
    return {

      categories: [],
      textures: [],
      colors: [],
      sizes: [],

      //*** begin : washing list
      // washPanel: false,
      btnActive:true,
      switchPanel: false,
      washingShop: "",
      washingShopList: [],
      infoNote: "",
      washingDate:{},
      displayItems: [],
      sendingItems:[],
      sendingReq:{},
      sendingAction: 1, // 1: ส่งซัก, 2: รับชุดซักกลับ, 3: ไม่ส่งซัก
      qty: [],
      // remaining: [],
      minimumRemaining: [],


      //*** end : washing list


    };
  },
  computed: {
    thePanel(){

      return this.$store.getters["washingList/washingPanel"];
    },
      //  *** BEGIN : 2020-12-12 : Search By branches
    getCurrentPackage(){

        return this.$store.getters["auth/currentPackage"];
    },

     //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches(){
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },

     //  *** BEGIN : 2020-11-28 : Search By branches
    selectedItem(){
      // console.log('this.$store.getters["washingList/selected"] >>> ',this.$store.getters["washingList/selected"]);

      return this.$store.getters["washingList/sendingItem"];
    },





  },

  async created() {


    // *** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // var today = new Date();
    // // today = this.formatDate(today);
    // this.washingDate.start = today;
    // this.washingDate.end = this.addDay(today,1);

    // ************ WASHING 2021-09-28
    var list  = this.$store.getters["washingList/sendingItem"];
    for (let ind = 0; ind < list.length; ind++) {
          this.qty[ind] = 0;
      }

    await this.$store.dispatch(
      "productGroup/getAllInfo"
    );
    this.categories = this.$store.getters["productGroup/categories"];
    this.textures = this.$store.getters["productGroup/textures"];
    this.colors = this.$store.getters["productGroup/colors"];
    this.sizes = this.$store.getters["productGroup/sizes"];

    this.switchPanel = this.$store.getters["washingList/washingPanel"];
    // await this.$store.dispatch("washingList/fetchList",params);


    //*** Load WASHING SHOP INFO */

    var shopRes = null;

    try {
      shopRes = await this.$http.post(
        this.$store.state.apiURL + "/api/v2/orderheader/clean-fix/getWnfShop",
        {},
        this.$store.state.auth.apiHeader
      );


      this.washingShopList = shopRes.data.itemList.filter(x=>x.status===true);
      this.$store.commit("washingList/SET_WNFSHOP",this.washingShopList);

    } catch (error) {
      shopRes = null;

    }



    this.branchrad = this.getCurrentBranch.branchId;


  },

  async mounted() {
    await this.getRemaining();
  },

  methods: {

    async getRemaining(){
      var req = [];
   

      if(Object.entries(this.washingDate).length === 0){
        this.minimumRemaining = [];
        for (let ind = 0; ind < this.selectedItem.length; ind++) {
          var one = {
            result: 0,
            remaining: 0
          };
          this.$set(this.minimumRemaining,ind , one);
          console.log("(1) this.minimumRemaining ",this.minimumRemaining);
          // this.minimumRemaining.unshift(one);
        }
      }
      else if(this.washingDate === null ){
        for (let ind = 0; ind < this.selectedItem.length; ind++) {
              var one2 = {
                result: 0,
                remaining: 0
              };
              this.$set(this.minimumRemaining,ind , one2);
              // this.minimumRemaining.unshift(one);
              console.log("(3) this.minimumRemaining ",this.minimumRemaining);
            }
      }
      else{

          for (var ind = 0; ind < this.selectedItem.length; ind++) {
              const ele = this.selectedItem[ind];
              var item = {
                productItemId: ele.productItemId,
                code: ele.code,
                pickupDate: Object.entries(this.washingDate).length === 0 ? new Date() :this.washingDate.start,
                returnDate: Object.entries(this.washingDate).length === 0 ? new Date() :this.washingDate.end,
                branchId: this.getCurrentBranch.branchId,
                qty: 0
              };
              req.unshift(item);
            }


          var res = await this.$http.post(this.$store.state.apiURL + "/api/orderdetail/check-remaining", req , this.$store.state.auth.apiHeader);
          this.minimumRemaining = res.data;
          console.log("(2) this.minimumRemaining ",this.minimumRemaining);


      }


    },

    async sendingToWash(){
      //*** VALIDATE ***/

      if(  (this.sendingAction === 1) &&  ( this.washingDate === null ||  Object.entries(this.washingDate).length <= 0 ) ){
          this.$swal({
            icon: 'warning',
            title: 'กรุณาเลือกวันที่ในการส่งซัก',
          });

          return;
      }
      if(  (this.sendingAction === 1) &&  ( this.washingShop === null || this.washingShop === undefined ||  Object.entries(this.washingShop).length <= 0 ) ){
          this.$swal({
            icon: 'warning',
            title: 'กรุณาเลือกร้านที่ต้องการส่งซัก',
          });

          return;
      }

    if( this.selectedItem.length <= 0)
    {
      this.$swal({
            icon: 'warning',
            title: 'กรุณาชุดที่ต้องการส่งอย่างน้อย 1 ชุด',
          });

          return;
    }



      this.btnActive = !this.btnActive;
      // this.$vs.loading();

       var wnfOrderArray = [];
      for (let ind = 0; ind < this.selectedItem.length; ind++) {

            if(this.qty[ind] <= 0){
              var text = "สินค้ารหัส " +  this.selectedItem[ind].code + " ยังไม่ได้เลือกจำนวน"
              this.$swal({
                icon: 'warning',
                text: text,
              });
              this.btnActive = !this.btnActive;
              return;
            }

            var item = {
              code: this.selectedItem[ind].code,
              qty: this.qty[ind]
            };

            wnfOrderArray.unshift(item);
      }
      // console.log("this.selectedItem >>> ",this.selectedItem);
      // console.log("this.washingDate.start >>> ",this.formatDate(this.washingDate.start));
      // console.log("this.washingDate.end >>> ",this.formatDate(this.washingDate.end));
      // console.log("remark >>> ",this.infoNote);
      // console.log("this.getCurrentBranch.branchId >>> ",this.getCurrentBranch.branchId);
      // console.log("this.washingShop.wnfShopId >>> ",this.washingShop.wnfShopId);
      // console.log("wnfOrderArray >>> ",wnfOrderArray);

       const req = {
                  sendingDate: this.formatDate(this.washingDate.start),
                  receivingDate: this.formatDate(this.washingDate.end),
                  remark: this.infoNote,
                  branchId: this.getCurrentBranch.branchId,
                  wnfShopId: this.washingShop.wnfShopId,
                  wnfOrderDetails: wnfOrderArray
              };

        try{
          await this.$store.dispatch("washingList/sendToWashFromStock", req);
          this.switchPanel = false;
          this.removeSelected();
          // this.btnActive = !this.btnActive;

         } catch (error) {

            this.btnActive = !this.btnActive;
         }

        await this.$store.dispatch("washingList/getOrderHeader");
        await this.$store.dispatch("washingList/getOrderDetail");

    },
    removeSelected(){

        this.$store.dispatch("washingList/clearAll");

    },
    remainingText(maxText, theText){
          if(theText === undefined || theText === null) theText = "";
          this.blockText = theText;
          this.previousText
          var t = maxText - theText.length;
          if(t <= 0) return 0;
          else
            return t;
        },
        async closeSendItem() {
        this.sendingReq = {};
        // this.washPanel = false;
        this.$store.commit("washingList/REMOVE_SENDING_ITEM");
        this.$store.commit("washingList/UPDATE_PANEL",false);
        this.sendingItems = [];
        this.washingDate = null;
        this.washingShop = null;
        this.infoNote = "";
        this.sendingAction = 0;



        // this.$store.commit("washingList/UPDATE_PANEL",false);
    },
      getFullpath(mainImage) {
        if (
          mainImage === undefined ||
          mainImage === null ||
          mainImage === "" ||
          mainImage === this.$store.state.imageStore
        ) {
          return this.$store.state.defaultImage;
        } else {
          return this.$store.state.imageStore + mainImage;
        }
      },
      getCategoryName(codename) {
      var c = this.categories.find(x => x.code === codename);

      var categoryName = "";
      if (c === null || c === undefined) {
        categoryName = "ไม่มี";
      } else {
        categoryName = c.description;
      }

      return categoryName;
    },
    getColorName(codename) {
      var c = this.colors.find(x => x.code === codename);

      var colorName = "";
      if (c === null || c === undefined) {
        colorName = "ไม่มี";
      } else {
        colorName = c.description;
      }

      return colorName;
    },
    getSizeName(codename) {
      var c = this.sizes.find(x => x.code === codename);

      var colorName = "";
      if (c === null || c === undefined) {
        colorName = "ไม่มี";
      } else {
        colorName = c.description;
      }

      return colorName;
    },

  },

  components: {
    FilterCleanFixSidebar,
    Multiselect,
    BEmbed,
    Prism,
    Datepicker,
    // VueRangedatePicker,
    // VueHotelDatepicker,
    "v-select": vSelect,
    // HotelDatePicker,
    Selection1

  },
  watch: {
    washingDate: {
      async handler() {

        console.log("washingDate >>> ",this.washingDate);
        for (let ind = 0; ind < this.selectedItem.length; ind++) {
          this.qty[ind] = 0;
        }

         // ถ้าปฎิทินโดนลบ ให้เซ็ตค่า max ของจำนวนที่เลือกใหม่
        if(this.washingDate === null || this.washingDate === undefined)
        {
          this.minimumRemaining = [];
           for (let ind = 0; ind < this.selectedItem.length; ind++) {
            var one = {
                result: 0,
                remaining: 0
              };
              this.$set(this.minimumRemaining,ind , one);
           }
        }
        else{
           await this.getRemaining();
        }



      },
      deep: true
    },
    // selectedItem: {
    //   handler() {
    //     for (let ind = 0; ind < this.selectedItem.length; ind++) {
    //       this.qty[ind] = 0;
    //     }
    //   },
    //   deep: true
    // },
    thePanel(newvalue){

      this.switchPanel = newvalue;
    },


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  }
};
</script>

<style>

.vs-dropdown--menu{
  width: 170px;
}

.multiselect__content-wrapper .multiselect-leave-active{
  z-index: 99999 !important;
}

.vs__search{
  display: none !important;
}
.clear-btn:hover{
   text-decoration: underline;
}
/* .vs-pagination--nav {
  display: none;
} */

.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.checkqueuelist {
  margin-top: 0;
}
.input-date {
  width: 100% !important;
}

.dropdown-toggle .clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none;
}
.selected-tag {
  font-size: 12px;
}

/* VUE HOTEL DATEPICKUP */
.previous-arrow .offset-2 .disabled {
  display: block !important;
}

/* .calendar {
  position: relative !important;
} */

/************* VUE HOTEL DATEPICKUP **************/

.datepicker__input {
  font-size: 12px !important;
  line-height: 4 !important;
  padding: 4px 25px 2px !important;
}

.con-vs-popup .vs-popup {
  width: 750px;
}

.con-vs-popup .vs-popup .datepicker {
  left: -285px;
  padding: 0 10px;
  z-index: 999 !important;
}

.datepicker.datepicker--closed {
  display: none;
}
.con-vs-popup .vs-popup .datepicker__inner {
  padding: 0;
  float: left;
  padding-bottom: 5px;
}

.con-vs-popup .vs-popup .datepicker__month-day {
  visibility: visible;
  text-align: center;
  margin: 0;
  border: 0;
  height: 25px;
  padding-top: 5px !important;
  vertical-align: middle;
}

.con-vs-popup .vs-popup .datepicker__week-row {
  border-bottom: 5px solid #fff;
  height: 20px;
}

.zoomimage {
  width: 400px;
}
.datepicker__tooltip {
  display: none !important;
}

.demo-alignment {
  vertical-align: middle;
}

li {
  margin: 0 !important;
}

@media only screen and (max-width: 578px) {
  #main-background {
    width: 100% !important;
    padding: 0 !important;
  }
  #controller-panel {
    width: 91% !important;
  }
}
</style>
