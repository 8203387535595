
<template lang='pug'>

    //*** POP UP : SEND to WASH
    div
      vs-popup(classContent='popup-example' title='ข้อมูลแคตาล็อก' :active.sync='catalogPanel')
        //*** หัวข้อ
        vs-row.row-form(vs-w='12' style="background-color:rgb(248, 248, 248)" )
          vs-col.form-label.py-4.px-6(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
            h4(style="font-size:1.5rem;") ปรับจำนวนก่อนพิมพ์การ์ด

        //*** เส้นแบ่ง
        //- vs-divider.mb-0(v-if="sendingAction===1"  )

        .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%; padding: 20px 0px;')

          //*** สาขา
          vs-row.row-form(vs-w='12')
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='7' vs-sm='7' vs-xs='7' style='z-index : 9995 !important;')
              multiselect(style='width:80%; z-index : 9999 !important;' v-model='chooseOne' :options='chooseOption'  placeholder="พิมพ์เพื่อค้นหา",)
                span(slot='noResult') ไม่พบข้อมูล



          //*** ROW OF SENDING TO WASH
          //*** POPUP
          vs-row(style='display: flex;width: 100%;padding: 20px 0px;margin-bottom: 30px;border-bottom: 1px solid #e4e4e4;' vs-w='12' v-for="(sitem, cat_index) in catalogList" :key="cat_index")
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='3' vs-sm='3' vs-xs='3' vs-offset='1')
              div(style='vertical-align: text-top;')
                img.responsive.rounded(:src='getFullpath(sitem.mainImage)' alt='user-upload' style='width:100px;')
            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='5' vs-sm='5' vs-xs='5')
              .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%;')

                //*** ข้อมูลสินค้า
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:1.2rem;color: #4a90e2;") {{sitem.name}}
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") ประเภท : {{getCategoryName(sitem.category)}}

                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") สี : {{getColorName(sitem.color)}}

                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") ไซส์ : {{getSizeName(sitem.size)}}

                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='flex-start' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:0.8rem;") รหัส : {{sitem.code}}

            vs-col(vs-type='flex' vs-justify='flex-start' vs-align='center' vs-lg='2' vs-sm='2' vs-xs='2')
              .grid-layout-container.alignment-block(style='vertical-align:text-top ;width:100%;')
                //*** จำนวน
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='center' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    span(style="font-size:1.2rem;") จำนวน
                vs-row.row-form(vs-w='12')
                  vs-col.form-label.px-2(vs-type='flex' vs-justify='center' vs-align='flex-start' vs-lg='12' vs-sm='12' vs-xs='12')
                    vue-number-input( v-model="qty[cat_index]" :min="0" size="small" inline controls center)

                    //- vs-input-number(style="width:50px; color: red;", min="0", :max="minimumRemaining[cat_index].remaining", v-model="qty[cat_index]")
                    //- span(style="font-size:1.2rem;") {{sitem.reservedQuantity}}

          //*** เส้นแบ่ง
          vs-divider.mb-0


          //*** ปุ่ม
          vs-row(vs-w='12' vs-type='flex' vs-justify='flex-end' style=' padding : 20px 20px; background-color: #F8F8F8; ')

            vs-button.btn-success(style='width:150px; margin-right: 20px;', @click="submitCatalog()" , :disabled='!btnActive' ) ตรวจสอบการ์ด

            vs-button(style='background-color:#4A4A4A !important; width:120px;' @click='closeCatalogPanel()' type='filled') ยกเลิก





</template>

<script>

import { BEmbed } from 'bootstrap-vue'
import Multiselect from "vue-multiselect";
import Prism from "vue-prism-component";
import vSelect from "vue-select";
// import HotelDatePicker from "vue-hotel-datepicker";
import Datepicker from "vuejs-datepicker";
import shapeFormat from "../../mixins/shapeFormat.js";
// import ProductService from "./Services/ProductService";
import Selection1 from "./Components/Selection1";
// import UpdateStockSidebar from './Components/UpdateStockSidebar.vue'
import FilterCleanFixSidebar from './Components/FilterCleanFixSidebar.vue'
import VueNumberInput from "@chenfengyuan/vue-number-input";

// const productService = new ProductService();


export default {
  mixins: [shapeFormat],
  data() {
    return {
      chooseOne: "พิมพ์อย่างละ 1",
      chooseOption: ["พิมพ์อย่างละ 1", "พิมพ์เท่าจำนวนที่มีในสาขา", "พิมพ์เท่าจำนวนที่มีในสต็อค"],
      categories: [],
      textures: [],
      colors: [],
      sizes: [],

      //*** begin : washing list
      // washPanel: false,
      btnActive:true,
      catalogPanel: false,
      washingShop: "",
      washingShopList: [],
      infoNote: "",
      washingDate:{},
      displayItems: [],
      sendingItems:[],
      sendingReq:{},
      sendingAction: 1, // 1: ส่งซัก, 2: รับชุดซักกลับ, 3: ไม่ส่งซัก
      qty: [],
      // remaining: [],
      minimumRemaining: [],


      //*** end : washing list


    };
  },
  computed: {
    thePanel(){

      return this.$store.getters["productList/catalogPanel"];
    },
      //  *** BEGIN : 2020-12-12 : Search By branches
    getCurrentPackage(){

        return this.$store.getters["auth/currentPackage"];
    },

     //  *** BEGIN : 2020-11-28 : Search By branches
    getCurrentBranch(){
      return this.$store.getters["auth/currentBranch"];
    },
    getMyBranches(){
      // console.log('@@@@PICKUP myBranches >>> ',this.$store.getters["auth/myBranches"]);
      return this.$store.getters["auth/myBranches"];
    },

     //  *** BEGIN : 2020-11-28 : Search By branches
    catalogList(){
      // console.log('this.$store.getters["productList/selected"] >>> ',this.$store.getters["productList/selected"]);

      return this.$store.getters["productList/catalog"];
    },





  },

  async created() {


    // *** >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // var today = new Date();
    // // today = this.formatDate(today);
    // this.washingDate.start = today;
    // this.washingDate.end = this.addDay(today,1);

    // ************ WASHING 2021-09-28
    for (let ind = 0; ind < this.catalogList.length; ind++) {
          this.qty[ind] = 1;
      }

    await this.$store.dispatch(
      "productGroup/getAllInfo"
    );
    this.categories = this.$store.getters["productGroup/categories"];
    this.textures = this.$store.getters["productGroup/textures"];
    this.colors = this.$store.getters["productGroup/colors"];
    this.sizes = this.$store.getters["productGroup/sizes"];

    this.catalogPanel = this.$store.getters["productList/catalogPanel"];
    // await this.$store.dispatch("productList/fetchList",params);


    //*** Load WASHING SHOP INFO */

    // var shopRes = null;

    // try {
    //   shopRes = await this.$http.post(
    //     this.$store.state.apiURL + "/api/v2/orderheader/clean-fix/getWnfShop",
    //     {},
    //     this.$store.state.auth.apiHeader
    //   );


    //   this.washingShopList = shopRes.data.itemList.filter(x=>x.status===true);
    //   this.$store.commit("productList/SET_WNFSHOP",this.washingShopList);

    // } catch (error) {
    //   shopRes = null;

    // }



    // this.branchrad = this.getCurrentBranch.branchId;


  },

  async mounted() {
    // await this.getRemaining();
  },

  methods: {


    submitCatalog(){

      // *** EXPAND List

      let newlist = [];

      for (let ind = 0; ind < this.catalogList.length; ind++) {
        const ele = this.catalogList[ind];
        for (let jj = 0; jj < this.qty[ind]; jj++) {
          // const ele = this.qty[ind];
          newlist.push(ele);

        }
      }

      // console.log(newlist);

      this.$store.commit("productList/UPDATE_CATALOG", newlist);
      this.closeCatalogPanel();
      this.$router.push("/pages/catalog-page");
    },
    closeCatalogPanel() {
        // this.washPanel = false;
        // this.$store.commit("productList/REMOVE_CATALOG_ITEM");
        this.$store.commit("productList/OPEN_CATALOG_PANEL",false);

    },
    removeSelected(){

        // this.$store.dispatch("productList/clearAll");

    },
    remainingText(maxText, theText){
          if(theText === undefined || theText === null) theText = "";
          this.blockText = theText;
          this.previousText
          var t = maxText - theText.length;
          if(t <= 0) return 0;
          else
            return t;
        },

      getFullpath(mainImage) {
        if (
          mainImage === undefined ||
          mainImage === null ||
          mainImage === "" ||
          mainImage === this.$store.state.imageStore
        ) {
          return this.$store.state.defaultImage;
        } else {
          return this.$store.state.imageStore + mainImage;
        }
      },
      getCategoryName(codename) {
      var c = this.categories.find(x => x.code === codename);

      var categoryName = "";
      if (c === null || c === undefined) {
        categoryName = "ไม่มี";
      } else {
        categoryName = c.description;
      }

      return categoryName;
    },
    getColorName(codename) {
      var c = this.colors.find(x => x.code === codename);

      var colorName = "";
      if (c === null || c === undefined) {
        colorName = "ไม่มี";
      } else {
        colorName = c.description;
      }

      return colorName;
    },
    getSizeName(codename) {
      var c = this.sizes.find(x => x.code === codename);

      var colorName = "";
      if (c === null || c === undefined) {
        colorName = "ไม่มี";
      } else {
        colorName = c.description;
      }

      return colorName;
    },

  },

  components: {
    VueNumberInput,
    FilterCleanFixSidebar,
    Multiselect,
    BEmbed,
    Prism,
    Datepicker,
    // VueRangedatePicker,
    // VueHotelDatepicker,
    "v-select": vSelect,
    // HotelDatePicker,
    Selection1

  },
  watch: {
    chooseOne(value){

      console.log("chooseOne >>> ",this.chooseOne);
      for (let si = 0; si < this.catalogList.length; si++) {
        if(value === "พิมพ์อย่างละ 1"){
          this.$set(this.qty , si , 1 );

        }
        else if( value === "พิมพ์เท่าจำนวนที่มีในสาขา"){
          let stock = this.catalogList[si].stock;
          console.log("พิมพ์เท่าจำนวนที่มีในสาขา >>> ",stock);
          if(stock === null || stock === undefined || stock.length <= 0){
            this.$set(this.qty , si , 1 );
          }else{
            let thisStock = stock.filter(x => x.branchId === this.getCurrentBranch.branchId );
            console.log("thisStock >>> ",thisStock);
            this.$set(this.qty , si , thisStock[0].inStock );
          }
          this.getCurrentBranch
        }
        else if( value === "พิมพ์เท่าจำนวนที่มีในสต็อค"){
          this.$set(this.qty , si , this.catalogList[si].inStock );
        }

      }

      // console.log("this.qty  >>> ",this.qty);

    },
    thePanel(newvalue){

      this.catalogPanel = newvalue;
    },


    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  }
};
</script>

<style>

.vs-dropdown--menu{
  width: 170px;
}

.multiselect__content-wrapper .multiselect-leave-active{
  z-index: 99999 !important;
}

.vs__search{
  display: none !important;
}
.clear-btn:hover{
   text-decoration: underline;
}
/* .vs-pagination--nav {
  display: none;
} */

.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.checkqueuelist {
  margin-top: 0;
}
.input-date {
  width: 100% !important;
}

.dropdown-toggle .clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none;
}
.selected-tag {
  font-size: 12px;
}

/* VUE HOTEL DATEPICKUP */
.previous-arrow .offset-2 .disabled {
  display: block !important;
}

/* .calendar {
  position: relative !important;
} */

/************* VUE HOTEL DATEPICKUP **************/

.datepicker__input {
  font-size: 12px !important;
  line-height: 4 !important;
  padding: 4px 25px 2px !important;
}

.con-vs-popup .vs-popup {
  width: 750px;
}

.con-vs-popup .vs-popup .datepicker {
  left: -285px;
  padding: 0 10px;
  z-index: 999 !important;
}

.datepicker.datepicker--closed {
  display: none;
}
.con-vs-popup .vs-popup .datepicker__inner {
  padding: 0;
  float: left;
  padding-bottom: 5px;
}

.con-vs-popup .vs-popup .datepicker__month-day {
  visibility: visible;
  text-align: center;
  margin: 0;
  border: 0;
  height: 25px;
  padding-top: 5px !important;
  vertical-align: middle;
}

.con-vs-popup .vs-popup .datepicker__week-row {
  border-bottom: 5px solid #fff;
  height: 20px;
}

.zoomimage {
  width: 400px;
}
.datepicker__tooltip {
  display: none !important;
}

.demo-alignment {
  vertical-align: middle;
}

li {
  margin: 0 !important;
}

@media only screen and (max-width: 578px) {
  #main-background {
    width: 100% !important;
    padding: 0 !important;
  }
  #controller-panel {
    width: 91% !important;
  }
}
</style>
